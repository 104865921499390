export const pageTitleStyle = {
  fontWeight: 700,
  fontSize: '25px',
  color: '#003F62'
}

export const modalWindowStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  backgroundColor: "rgb(255, 255, 255)",
  border: 0,
  borderRadius: 8,
  boxShadow: 24,
  padding: 14,
  maxHeight: '90vh',
  fontFamily: 'roboto',
};

export const fabStyle = {
  boxShadow: '0 0 0 0',
  p: 2,
}

export const SvgOperationButtonsBlock = {
  position: 'absolute', left: 0, top: 0,
  width: 120, height: 40, transform: 'translateX(-50%)', display: 'flex', justifyContent: 'center',
  alignItems: 'center', backgroundColor: 'rgba(255,255,255,0.8)', borderRadius: 20, opacity: 0.8
}

export const twentyFour = {
  height: '24px',
  width: '24px',
}
