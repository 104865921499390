import {getProjects} from "../../LeftPanel/Context/Projects/ProjectsCommon";
import {otherDataAPI} from "../../../../api/api";
import {setUserDataAttr} from "../../../../redux/reducers/userReducer";
import {getMiscLayer, getMiscLayers} from "../../LeftPanel/Context/MiscLayers/MiscLayersCommon";
import {getMap1} from "../GlobalObjects";
import {getBlindMiscLayers} from "../Blind/BlindMiscLayersCommon";
import {addRedrawLayer} from "./redrawLayersIfNeeded";
import {dispatch} from "../../../Common/misc_functions";
import {setOrgInfoAttr} from "../../../../redux/reducers/organizationInfoReducer";

//TODO это все нужно убрать в редусер. Будет тяжело.
let tariff = null;
let species = {
  loading: false,
  loadingError: false, //указывает на то, что таблица не загружена и везде используется серый цвет
  data: null,
}

export function getSpecies() {
  return species;
}

export function setSpeciesData(data) {
  species = {...species, ...data}
  return species;
}

export function getTariff() {
  return tariff;
}

export function updateTariffOrgInfoLimits(orgInfo) {
  if (tariff) {
    tariff.current_limit = orgInfo.current_limit;
  }
}

/**
 * Возвращает название роли по установленым флагам обьекта
 * @param userOrTariff {{is_owner: boolean, is_admin: boolean, is_editor: boolean, is_full_access_user: boolean}}
 * @returns {string}
 */
export function getRoleName(userOrTariff) {
  const tariff = userOrTariff;
  if (!tariff)
    return '';
  if (tariff.is_owner)
    return 'Владелец';
  else
  if (tariff.is_admin)
    return 'Админ';
  else
  if (tariff.is_editor)
    return 'Редактор';
  else
  if (tariff.is_full_access_user)
    return 'Полный просмотр';
  else
    return 'Просмотр';
}

/**
 * Сравнивает два обьекта с ролями по старшинству
 * @param u1 {object} - первый объект
 * @param u2 {object} - второй объект
 * @returns {number} - 0 - если равны, >0 - если первый круче, <0 - если второй круче
 */
export function compareRoles(u1, u2) {
  let u1score = 0;
  let u2score = 0;

  u1score += u1.is_owner ? 100000 : 0;
  u1score += u1.is_admin ? 10000 : 0;
  u1score += u1.is_editor ? 1000 : 0;
  u1score += u1.is_full_access_user ? 100 : 0;
  u1score += u1score === 0 ? 10 : 0;
  u1score += u1.only_mobile ? -1 : 0;
  u1score += u1.deny_access ? -1000000 : 0;

  u2score += u2.is_owner ? 100000 : 0;
  u2score += u2.is_admin ? 10000 : 0;
  u2score += u2.is_editor ? 1000 : 0;
  u2score += u2.is_full_access_user ? 100 : 0;
  u2score += u2score === 0 ? 10 : 0;
  u2score += u2.only_mobile ? -1 : 0;
  u2score += u2.deny_access ? -1000000 : 0;

  return u1score - u2score;
}

export function setTariff(userInfo, orgInfo) {
  if (userInfo && orgInfo) {
    tariff = structuredClone(userInfo.organization['tariff'])
    tariff.current_limit = orgInfo.current_limit;
    tariff.count_editor = orgInfo.tariff.count_editor;
    tariff.count_user = orgInfo.tariff.count_user;
    tariff.name = tariff.name.toUpperCase()
    tariff.userID = userInfo.id;
    tariff.is_1c_user = orgInfo.using_1C;
    tariff.is_admin = userInfo.is_admin;
    tariff.is_owner = userInfo.is_owner;
    tariff.is_editor = userInfo.is_editor;
    tariff.is_full_access_user = userInfo.is_full_access_user;
    tariff.expired = !userInfo.organization["is_active"];
    tariff.paid_to = orgInfo.paid_to;
    //tariff.expired = true;
    tariff.planetable = userInfo.organization.planetable;
    tariff.using_1C = orgInfo.using_1C;
    tariff.created = orgInfo.created;
    tariff.orgId = orgInfo.id;
    tariff.orgTmMonitoring = orgInfo["using_transport_monitoring"] || false;
    /**
       * @type {{stockpiles: {}, planetables: {}, operational_report: {}, '1c_reports': {}, '1c_sync': {}, transport_monitoring: {}}}
       */
    tariff.modules = userInfo.modules;
    //обновляем orgData после изменения в тарифе (обязательно для хедера)
    const orgData = window.store.getState().organizationInfoReducer.organizationData;
    dispatch(setOrgInfoAttr({organizationData: {...orgData}}))
    return tariff.expired;
  }
  return undefined;
}

export function updatePlanetabletsLayerData(dispatch) {
  function updateLayer(planetablets, notForBlind) {
    const map = getMap1()
    if (map) {
      if (planetablets.l) {
        const lay = getMiscLayer('planetablets', notForBlind)
        map.elz_zoom.removeLayer(planetablets.l)
        planetablets.l = lay;
        map.elz_zoom.addLayer(lay, planetablets.minZoom, planetablets.maxZoom)
        addRedrawLayer(lay)
      }
    }
  }

  otherDataAPI.user.getUserInfo().then(res => {
    tariff.planetablets = res.data.organization.planetablets;
    updateLayer(getMiscLayers().planetablets, true)
    updateLayer(getBlindMiscLayers().planetablets, false)
    dispatch(setUserDataAttr({userInfo: res.data}))
  }).catch(err => console.error(err))
}

export const cannotIDo = { //Возвращает 0 если все норм или код ошибки
  expiredTariffActions() {
    return (tariff && tariff.expired) ? 0 : 1;
  },
  lightTariffActions() {
    return (tariff && tariff.name === 'ЛАЙТ') ? 1 : 0;
  },
  ownerAction() {
    if (tariff)
      if (tariff.is_owner)
        return 0;
    return 1;
  },
  adminAction() {
    if (tariff)
      if (tariff.is_admin)
        return 0;
    return 1;
  },
  editorAction() {
    if (tariff && !tariff.expired && tariff.is_editor)
        return 0;
    return 1;
  },
  fullViewAction() {
    return tariff && tariff.is_full_access_user?0:1;
  },
  createWorkspace() {
    if (!tariff) {
      return 255;
    }
    if (tariff.id === 4)
      return 0;
    if (getProjects().data.length < tariff["count_rental_contracts"])
      return 0;
    else
      return 1;
  },
  createCA() {
    if (!tariff) {
      return 255;
    }
    if (tariff.id === 1)
      return 1;
    if (tariff.id === 2 || tariff.id === 3) {
      if (tariff.is_admin || tariff.is_owner)
        return 0;
    } else
      return 0;
    return 2;
  },
  viewMiscLayer(layerKey) { //видеть слой планшетов
    if (!tariff) {
      return 255;
    }
    if (layerKey !== 'planetablets')
      return 0;
    if (tariff.id === 1)
      return 1;
    return 0;
  },
  editMiscLayer(layerKey) {
    if (!tariff) {
      return 255;
    }
    if (layerKey !== 'planetablets')
      return 0;
    if (tariff.id === 1)
      return 1;
    return 0;
  },
  addUserMiscLayer() {//добавлять свои слои
    if (!tariff) {
      return 255;
    }
    if (tariff.id === 1 || tariff.id === 2)
      return 1;
    if (tariff.is_admin || tariff.is_owner)
      return 0;
    return 2;
  },
  useUserLayers() {//смотреть/включать/выключать свои слои
    if (!tariff) {
      return 255;
    }
    if (tariff.id === 1 || tariff.id === 2)
      return 1;
    return 0;
  },
  useCAs() { //пользовать деляновый список и деляны
    if (!tariff) {
      return 255
    }
    if (tariff.id === 1)
      return 1;
    return 0;
  },
  useRoads() {
    return !this.lightTariffActions()?0:1;
  },
  editRoads() {
    return tariff.is_editor && !this.lightTariffActions()?0:1;
  },
  /*usePillars() {
    return !this.lightTariffActions()?0:1;
  },*/
  editPillars() {
    return this.editorAction() && !this.lightTariffActions() ? 0 : 1;
  },
  usePrinting() {//пользовать печать
    if (!tariff) {
      return 255;
    }
    if (tariff.id === 1)
      return 1;
    return 0;
  },
  useSentinelLayer() { //слои Sentinel
    if (!tariff) {
      return 255;
    }
    if (this.lightTariffActions())
      return 1;
    if (tariff.expired)
      return 2;
    return 0;
  },
  changeWarehouses() { //редактировать склады
    if (!tariff) {
      return 255;
    }
    if (!(tariff.is_admin || tariff.is_owner))
      return 1;
    return 0;
  },
  changeCAData() { //редактирование справочной инфы деляны (не для 1с)
    if (!tariff)
      return 255;
    if (tariff.using_1C)
      return 1;
    if (!tariff.is_editor)
      return 2;
    return 0;
  },
  use1CData() {
    return (tariff && tariff.using_1C)?0:1;
  },
  useCorporateTariffAction() {
    return (tariff && tariff.name === 'КОРПОРАТИВНЫЙ')?0:1;
  },
  //Модули
  //Доступ к модулям только у редакторов и выше, у которых тариф не ЛАЙТ
  useStockpilesModule() { //штабли
    return (!this.lightTariffActions() && tariff?.is_editor && tariff?.modules.stockpiles && tariff.modules.stockpiles['access']) ? 0 : 1;
  },
  writeStockpilesModule() {
    return (!this.lightTariffActions() && tariff?.is_editor && tariff?.modules.stockpiles && tariff.modules.stockpiles['writable']) ? 0 : 1;
  },
  usePlanetabletsModule() { //планшеты
    return (!this.lightTariffActions() && tariff?.is_editor && tariff?.modules.planetables && tariff.modules.planetables['access']) ? 0 : 1;
  },
  writePlanetabletsModule() {
    return (!this.lightTariffActions() && tariff?.is_editor && tariff?.modules.planetables && tariff.modules.planetables['writable']) ? 0 : 1;
  },
  useORModule() { //оперативный учет
    return (!this.lightTariffActions() && tariff?.is_editor && tariff?.modules.operational_report && tariff.modules.operational_report['access']) ? 0 : 1;
  },
  writeORModule() {
    return (!this.lightTariffActions() && tariff?.is_editor && tariff?.modules.operational_report && tariff.modules.operational_report['writable']) ? 0 : 1;
  },
  use1CReportsModule() { //отчеты 1с. для этого модуля нет прав на запись
    //if (tariff.orgId !== 72 || tariff.orgId !== 98) //prod 1038
      return (!this.lightTariffActions() && tariff?.is_editor && tariff.using_1C && tariff?.modules["1c_reports"] && tariff.modules["1c_reports"].access) ? 0 : 1;
    //return 2;
  },
  use1CSyncModule() { //синхронизация 1С. для этого модуля нет прав на запись
    return (tariff && !this.lightTariffActions() && tariff?.is_editor && tariff?.using_1C && tariff?.modules["1c_sync"] && tariff?.modules["1c_sync"].access) ? 0 : 1;
  },
  useLoggingComplexMonitoringModule() { //мониторинг транспорта. для этого модуля нет прав на запись
    return (!this.lightTariffActions() && tariff.orgTmMonitoring && tariff?.is_editor && tariff.modules?.transport_monitoring?.access) ? 0 : 1;
  },
}
