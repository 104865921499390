import {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {getMap1} from "../GlobalObjects";

export function ZoomComponent() {
  const map = getMap1()
  const [zoom, setZoom] = useState(map.getZoom())

  function changeZoom() {
    const zoom = getMap1().getZoom()
    setZoom(zoom)
  }

  useEffect(() => {
      const map = getMap1()
      map.once('zoomend', changeZoom)
  })

  if (!map)
    return null;

  //const mpp = 40075016.686 * Math.abs(Math.cos(map.getCenter().lat * Math.PI / 180)) / Math.pow(2, map.getZoom() + 8);
  return (
    <Box id={'zoooom'}>
      <Typography variant={'h4'} style={{
        color: 'white',
        margin: 0,
        fontFamily: '"Helvetica", "Arial", sans-serif',
        fontWeight: '600',
        WebkitTextStroke: '1px black',
        userSelect: 'none',
      }}>
        {zoom.toString()}
      </Typography>
    </Box>
  )
}
