import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {IconButton} from "@mui/material";
import {useState} from "react";
import {FilterPopup} from "./FilterPopup";

export function FilterHeaderButton({style, data, field, filterClose}) {
  const [menuAnchor, setMenuAnchor] = useState(null)

  //const data = useSelector(state => state.casFullInfoReducer.cas.data)

  function onClick(e) {
    setMenuAnchor(e.target)
  }

  function popupClose(changed) {
    setMenuAnchor(null)
    filterClose(changed)
  }

  return (
    <>
      <IconButton disableRipple style={{...style, position: 'inherit'}} onClick={onClick}
                  color={field.filter ? 'primary' : ''}>
        <FilterAltOutlinedIcon style={style}/>
      </IconButton>
      {!!menuAnchor &&
        <FilterPopup data={data} field={field} menuAnchor={menuAnchor} filterClose={popupClose}/>
      }
    </>
  )
}
