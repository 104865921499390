import L from "leaflet";
import {getMap1} from "../../Map/GlobalObjects";
import {getSentinelDate} from "../../Map/Common/DateTimeFunctions";
import {getBlindBaseLayers} from "../../Map/Blind/BlindBaseLayersCommon";
import {getUserSettings} from "../../Map/Common/userSettings";
import {cannotIDo} from "../../Map/Common/tariffs";

const baseLayersData = {
  osm: {
    name: "Карта OSM",
    type: 'tiles',
    show: false,
    l: null,
    opacity: 1,
    pane: "base",
    feas: false,
    zIndex: 1,
    zoomMin: 0,
    zoomMax: 0,
    url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
  eeco: {
    name: "ЕЭКО",
    type: 'tiles',
    show: false,
    l: null,
    opacity: 1,
    pane: "base",
    feas: false,
    zIndex: 1,
    url: 'https://ngw.fppd.cgkipd.ru/tile/56/{z}/{x}/{y}.png',
  },
  google_schema: {
    name: "Карта Google",
    type: 'tiles',
    l: null,
    show: false,
    opacity: 1,
    pane: "base",
    feas: false,
    zIndex: 2,
    url: 'https://mt0.google.com/vt/lyrs=m&hl=ru&x={x}&y={y}&z={z}&s=Ga',
  },
  google_sat: {
    name: "Спутник Google",
    type: 'tiles',
    l: null,
    show: false,
    opacity: 1,
    pane: "base",
    feas: false,
    zIndex: 3,
    url: 'https://mt0.google.com/vt/lyrs=y&hl=ru&x={x}&y={y}&z={z}',
  },
  arcgis_sat: {
    name: "Спутник ArcGis",
    type: 'tiles',
    l: null,
    show: false,
    opacity: 1,
    pane: "base",
    feas: false,
    zIndex: 4,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  },
  sentinel: {
    name: "Спутник Sentinel",
    type: 'sentinel',
    l: null,
    show: false,
    opacity: 1,
    pane: "sentinel",
    time1: undefined,
    time2: undefined,
    cc: 60.0,
    channel: 'TRUE-COLOR-S2L2A',
    feas: true,
    zIndex: 5,
    url: null,
  },
}

export function initSentinelDate(layers) {
    const sentinelLayer = layers.sentinel;
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
    const month = new Date(new Date().setDate(new Date().getDate() - 30))
    const time1 = getSentinelDate(month)
    const time2 = getSentinelDate(yesterday)
    //sentinelLayer.url = process.env.REACT_APP_BASE_URL + `api/v1/gis/santinel_px/wms?REQUEST=GetTile&RESOLUTION=10&LAYERS=TRUE-COLOR-S2L2A&TILEMATRIXSET=PopularWebMercator256&gamma=0.8&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&TIME=${time1}/${time2}&MAXCC=60`;
    //sentinelLayer.url = process.env.REACT_APP_BASE_URL + `api/v1/gis/santinel_px/wmts?REQUEST=GetTile&version=1.0.0&bbox=bbox-epsg-3857&RESOLUTION=10&LAYER=${sentinelLayer.channel}&TILEMATRIXSET=PopularWebMercator256&TILEMATRIX={z}&TILEROW={x}&TILECOL={y}&TIME=${time1}/${time2}&MAXCC=60`;
    //its work:
    //sentinelLayer.url = `https://services.sentinel-hub.com/ogc/wmts/7b4be6ad-1e88-440f-8216-538819243459/?REQUEST=GetTile&LAYER=TRUE-COLOR-S2L2A&TILEMATRIXSET=PopularWebMercator256&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}`;
    sentinelLayer.time1 = month;
    sentinelLayer.time2 = yesterday;
    sentinelLayer.url = getSentinelUrl(sentinelLayer.channel, time1, time2, 60)
}

export function initBaseLayers(is_blind) {
  const layersData = is_blind?getBlindBaseLayers():getBaseLayers()
  let settings = is_blind?getUserSettings().blind.baseLayers:getUserSettings().baseLayers;
  initSentinelDate(layersData)
  Object.entries(settings).map(([key, value]) => { //залить настройки
    if (!layersData[key]) //если в настройках остались убранные слои
      return null;
    const layer = layersData[key]
    layer.opacity = value.opacity;
    if (key === 'sentinel' && (cannotIDo.lightTariffActions() || !cannotIDo.expiredTariffActions())) {
      layer.show = false;
    } else {
      layer.show = value.show;
    }
  })
  Object.keys(layersData).map((key) => {
    const layer = layersData[key];
    if (layer && layer.show) {
      if (!is_blind || getUserSettings().blind.enabled) {
        layer.l = createBaseLayer(key, true, is_blind)
        getMap1().addLayer(layer.l)
      }
    }
  })
}

export function getBaseLayers() {
  return baseLayersData;
}

export function resetBaseLayers() {
  const map = getMap1()
  Object.entries(baseLayersData).map(([key, data]) => {
    if (data.l)
      if (map.hasLayer(data.l))
        map.removeLayer(data.l)
    data.l = null;
    data.show = key === 'osm';
  })
}

const blOpts = {
  maxZoom: 20,
  noWrap: false,
  zIndex: 0,
  opacity: 0,
  transparent: false,
  detectRetina: true,
}

export function createBaseLayer(index, save = true, isBlind = false) {
  let layer = null;
  const data = isBlind?getBlindBaseLayers()[index]:baseLayersData[index];
  blOpts.opacity = data.opacity;
  blOpts.pane = data.pane;
  switch (data.type) {
    case 'tiles':
      layer = L.tileLayer(data.url, blOpts);
      break;
    case 'sentinel':
      const bl = {
        ...blOpts,
        minZoom: 7,
        pane: isBlind ? "blind_base" : "base",
        detectRetina: true,
        //format: 'image/png',
        //tileSize: 512,
      }
      layer = L.tileLayer.headers(data.url, bl)
      break;
  }
  if (save)
    data.l = layer;
  if (layer)
    layer.setZIndex(data.zIndex)
  return layer;
}

export function getSentinelUrl(layer, time1, time2, maxcc) {
  return process.env.REACT_APP_BASE_URL +
  `api/v1/gis/santinel_px/wmts?REQUEST=GetTile&LAYER=${layer}&bbox=bbox-epsg-3857&TILEMATRIXSET=PopularWebMercator256&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&TIME=${time1}/${time2}&MAXCC=${maxcc}`;
}
