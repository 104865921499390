import {TableCell} from "@mui/material";
import './common.css';

export function TableCellLink({item, field, style, linkClick, name}) {
  return (
    <TableCell
      style={style}
      className={'ellipsis'}
    >
      <a
        style={{color: 'rgb(25, 118, 210)'}}
        href={'#'}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (linkClick) {
            linkClick(field, item)
          }
        }}
      >
        {name}
      </a>
    </TableCell>
  )
}
