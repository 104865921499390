import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import store from "./redux/redux-store";
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter
    future={{
      v7_relativeSplatPath: false,
      v7_startTransition: false,
    }}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
)
