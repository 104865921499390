import {useDispatch, useSelector} from "react-redux";
import {emptyDeclarationState, setDeclarationXMLAttr} from "../../CAs/cuttingAreasReducer";
import {IconButton} from "@mui/material";
import {DeclarationLoadingDialog} from "../../CAs/DeclarationLoading/DeclarationLoadingDialog";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";

export function DeclarationLoadingButton() {
  const state = useSelector(state => state.cuttingAreasReducer.declatationXML)
  const dispatch = useDispatch()

  function openDialogHandler() {
    dispatch(setDeclarationXMLAttr({dialogOpen: true}))
  }

  function closeDialogHandler() {
    dispatch(setDeclarationXMLAttr({...emptyDeclarationState}))
  }

  return (
    <>
      <IconButton
        size={'small'}
        onClick={openDialogHandler}
      >
        <UploadFileOutlinedIcon/>
      </IconButton>
      {state.dialogOpen && <DeclarationLoadingDialog state={state} onClose={closeDialogHandler}/>}
    </>
  )
}
