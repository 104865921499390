import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {TableVirtuoso} from "react-virtuoso";
import {forwardRef, useState} from "react";
import {TableHeaderButtons} from "./TableHeaderButton";

const stickyCellStyle = {
  position: 'sticky',
  left: 0,
  backgroundColor: 'inherit',
}

const headerRowStyle = {
  //whiteSpace: 'nowrap',
  verticalAlign: 'top',
  //position: 'sticky',
}

function getCellStyle(field, sticky, header) {
  let style = {
    maxWidth: field.maxWidth,
    minWidth: field.maxWidth,
  }

  if (sticky) {
    if (!header)
      return {...stickyCellStyle, ...style}
    else {
      return {...stickyCellStyle, ...style, zIndex: 3}
    }
  }

  return style;
}

function HeaderCell({data, field, sortingFunc, updateFilters, sorting, style = {}}) {
  const [hide, setHide] = useState(true)

  function mouseEnter() {
    setHide(false)
  }

  function mouseLeave() {
    setHide(true)
  }

  function filterClose(update) {
    mouseLeave()
    if (update)
      updateFilters(field)
  }

  function sortingButtonClick(field) {
    let direction = sorting.direction;
    if (sorting.direction !== null && sorting.column === field.key) {
      direction = -1 * direction;
    } else {
      direction = 1;
    }
    sortingFunc(field, direction)
  }

  return (
    <TableCell
      style={style}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <span>{field.name}</span>
      <TableHeaderButtons
        data={data}
        hide={hide}
        field={field}
        sorting={sorting}
        sortingButtonClick={() => sortingButtonClick(field)}
        filterClose={filterClose}
      />
    </TableCell>
  )
}

function getTableComponents(selected, rowClick) {

  return {
    Table: (props) => <Table
      stickyHeader
      sx={{
        /*"& .MuiTableRow-root:hover": {
          backgroundColor: "#f5f5f5 !important",
        },*/
        "& .MuiTableRow-root": {
          backgroundColor: 'white',
        },
        "& .MuiTableRow-root.Mui-selected": {
          backgroundColor: '#f5f5f5 !important',
        },
      }}
      {...props}
    />,

    TableHead: TableHead,

    TableRow: forwardRef((props, ref) =>
      <TableRow /*hover*/ selected={props.item === selected} style={{backgroundColor: 'white'}} ref={ref} {...props}
                          onClick={() => rowClick(props.item)}
      />
    ),

    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref}/>),
  }
}

function HeaderCells(props) {
  return (
    <TableRow style={headerRowStyle}>
      {props.visibleFields.map((field, index) => (
        <HeaderCell key={index} data={props.data} field={field} sorting={props.sorting}
                    sortingFunc={props.fieldSorting}
                    updateFilters={props.updateFilters}
                    style={{...getCellStyle(field, index < props.stickyColumnsCount, true)}}
        />
      ))}
    </TableRow>
  )
}

export function TableWithBellsAndWhistles(props) {

  return (
    <TableVirtuoso
      ref={props._ref}
      data={props.data}
      components={getTableComponents(props.selected, props.rowClick)}
      fixedHeaderContent={() => <HeaderCells {...props} />}
      itemContent={(index, item) =>
        props.visibleFields.map((field, index) =>
          props.getCell(item, field, getCellStyle(field, index < props.stickyColumnsCount), props.linkClick))
      }
      //increaseViewportBy={100}
      //overscan={5}
    />
  )
}
