import {Box, List, ListItem, ListItemButton, ListItemIcon, Typography,} from "@mui/material";
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import {useDispatch, useSelector} from "react-redux";
import {setLeftDrawerCurTab} from "./LeftDrawerReducer";
import InsightsIcon from "@mui/icons-material/Insights";
import {MiscLayerChooser} from "./Context/MiscLayers/MiscLayersChooser";
import {CASTree} from "./Context/CAs/CASTree.jsx";
import {Projects} from "./Context/Projects/Projects";
import {
  componentIsNotRealized,
  leftDrawerCAUseForbidden,
  leftDrawerPrintingUseForbidden,
  leftDrawerRoadsUseForbidden,
} from "../Map/Common/Strings_RU";
import {cancelEdit, isShapeNode, showHideAllShapes} from "./Context/Projects/ProjectsCommon";
import {setShowMapDrawingPanel} from "./Context/Projects/projectsReducer";
import {resetAllDrawing} from "../Map/Common/Drawing/drawing";
import {PrintMap} from "./Context/Printing/PrintMap";
import {
  setPrintingShowLabels,
  setPrintingShowShapes,
  setPrintingShowStratums,
} from "./Context/Printing/printingReducer";
import {getCuttingAreas, removeCAsLabels} from "./Context/CAs/CuttingsAreas";
import {getMap1} from "../Map/GlobalObjects";
import {refreshMap} from "../Map/ELZMapReducer";
import {UserLayers} from "./Context/MiscLayers/UserLayers/UserLayers";
import {LockedIcon} from "../Map/Common/LockedIcon";
import {cannotIDo} from "../Map/Common/tariffs";
import {setSnack} from "../Map/Common/Dialog/Snack/snackReducer";
import {Infrastructure} from "./Context/Infrastructure/Infrastructure";
import {RoadsTree} from "./Context/Roads/RoadsTree";
import EditRoadOutlinedIcon from '@mui/icons-material/EditRoadOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {FullInfo} from "./Context/FullInfo/FullInfo";

const borderedItemStyle = {
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderLeft: "1px solid",
}

const transparentBorderStyle = {
  border: "1px solid transparent",
}

function getButtonIcon(index) {
  // врзвращает иконку кнопки
  let lock;
  switch (index) {
    case "projects":
      return <InsightsIcon color={"primary"} id={"projects"}/>;
    case "layers":
      return <LayersOutlinedIcon color={"primary"} id={"forestLayers"}/>;
    case "cas":
      lock = cannotIDo.useCAs() !== 0;
      return (
        <LockedIcon bottom={0} locked={lock} style={{height: 24}}>
          <FormatListBulletedIcon id={"plots"} color={!lock ? "primary" : ""}/>
        </LockedIcon>
      )
    case "roads":
      lock = cannotIDo.useRoads() !== 0;
      return (
        <LockedIcon bottom={0} locked={lock} style={{height: 24}}>
          <EditRoadOutlinedIcon id={"plots"} color={!lock ? "primary" : ""}/>
        </LockedIcon>
      )
    case "infrastructure":
      return <WorkOutlineIcon color={"primary"} id={"infrastructure"}/>;
    case "printing":
      lock = cannotIDo.usePrinting() !== 0;
      return (
        <LockedIcon
          width={24}
          heigth={24}
          bottom={0}
          locked={lock}
          style={{height: 24}}
        >
          <PrintOutlinedIcon color={!lock ? "primary" : ""} id={"print"}/>
        </LockedIcon>
      )
    case "fullInfo":
      lock = cannotIDo.usePrinting() !== 0;
      return (
        <LockedIcon
          width={24}
          heigth={24}
          bottom={0}
          locked={lock}
          style={{height: 24}}
        >
          <DescriptionOutlinedIcon color={!lock ? "primary" : ""} id={"print"}/>
        </LockedIcon>
      )
  }
}

/** Набор кнопок на левой панели. */
const buttons = {
  projects: {name: "Проекты"},
  layers: {name: "Слои"},
  cas: {name: "Деляны"},
  fullInfo: {name: 'Beta'},
  roads: {name: "Логистика"},
  infrastructure: {name: "Инфраструктура"},
  printing: {name: "Печать"},
}

export function getLeftDrawerButtons() {
  return buttons;
}

export function getChildren(index) {
  switch (index) {
    case "projects":
      return <Projects index={index}/>
    case "layers":
      return (
        <>
          <MiscLayerChooser index={index}/>
          <UserLayers/>
        </>
      )
    case "cas":
      return <CASTree/>
    /*    case 'filters':
      return <FiltersControl index={index}/>*/
    case 'roads':
      return <RoadsTree />
    case "infrastructure":
      return <Infrastructure/>
    case "printing":
      return <PrintMap index={index}/>
    case "fullInfo":
      return <FullInfo/>
    /*    case 5:
      return <Reports obj={buttons[index]}  />*/
    default:
      return (
        <Typography variant={"body1"}>{componentIsNotRealized}</Typography>
      )
  }
}

/** Левая панель с кнопками. */
export const LeftDrawer = () => {
  const stateLeft = useSelector((state) => state.leftDrawerReducer)
  const dispatch = useDispatch()

  // Обработка нажатия по кнопкам.
  function handleTabClick(index) {
    if (index === "fullInfo" && cannotIDo.useCAs() !== 0) {
      dispatch(setSnack("warning", leftDrawerCAUseForbidden));
      return;
    }
    if (index === "cas" && cannotIDo.useCAs() !== 0) {
      dispatch(setSnack("warning", leftDrawerCAUseForbidden));
      return;
    }
    if (index === "roads" && cannotIDo.useRoads() !== 0) {
      dispatch(setSnack("warning", leftDrawerRoadsUseForbidden));
      return;
    }
    if (index === "printing" && cannotIDo.usePrinting() !== 0) {
      dispatch(setSnack("warning", leftDrawerPrintingUseForbidden));
      return;
    }
    //curTab - old tab, index - new tab
    if (stateLeft.curTab === "projects" || (stateLeft.curTab === "printing" && index !== "projects")) {
      //Если идет редактирование, отменяем. т.к. надо скрыть все шейпы
      const curEditor = window.store.getState().layerEditingReducer.editor;
      if (isShapeNode(curEditor?.ownLayer.node)) {
        cancelEdit(curEditor.ownLayer.node)
      }
      showHideAllShapes(false)
      dispatch(setShowMapDrawingPanel(false));
      dispatch(refreshMap())
    } else {
      if (index === "projects") {
        showHideAllShapes(true)
        dispatch(setShowMapDrawingPanel(true));
      }
    }
    if (stateLeft.curTab === index) {
      //hide current panel
      dispatch(setLeftDrawerCurTab(null));
    } else {
      dispatch(setLeftDrawerCurTab(index));
    }
    if (index === "printing")
      dispatch(setPrintingShowShapes(false));
    if (stateLeft.curTab === "printing") {
      dispatch(setPrintingShowLabels(false));
      dispatch(setPrintingShowStratums(false));
      removeCAsLabels(getCuttingAreas().group, getMap1());
    }
    resetAllDrawing(dispatch)
  }

  return (
    <Box
      id={"left_drawer"}
      style={{display: "flex", flexDirection: "row", boxSizing: "border-box"}}
    >
      <List>
        {Object.entries(buttons).map(([key, obj]) => {
          return (
            <ListItem
              id={`lb_button_${key}`}
              key={key}
              disablePadding
              sx={{
                border:
                  stateLeft.curTab === key
                    ? {
                      ...borderedItemStyle,
                      borderColor: window.elz_theme.palette.primary.main,
                    }
                    : transparentBorderStyle,
                boxSizing: 'border-box',
              }}
            >
              <ListItemButton disableRipple onClick={() => handleTabClick(key)}>
                <ListItemIcon
                  sx={{minWidth: 0, justifyContent: "center"}}
                  title={obj.name}
                >
                  {getButtonIcon(key)}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}
