export const casAllFieldsList = [
  {key: 'status', name: 'Статус', maxWidth: 80, type: 'icons', sortType: 'status', filter: null, visible: true},
  {key: 'name', name: 'Название', maxWidth: 200, sortType: 'string', filter: null, visible: true},
  {key: 'volume_declaration', name: 'Отведено', maxWidth: 100, sortType: 'number', filter: null, visible: true},
  {key: 'volume_cut', name: 'Вырублено', maxWidth: 100, sortType: 'number', filter: null, visible: true},
  {key: 'number', name: 'Номер', maxWidth: 50, sortType: 'number', filter: null, visible: true},
  {key: 'allotments_numbers', name: 'Выделы', maxWidth: 200, sortType: 'number', filter: null, visible: true},
  {key: 'quarter_number', name: 'Квартал', maxWidth: 50, sortType: 'number', filter: null, visible: true},
  {key: 'declaration', name: 'Декларация', maxWidth: 200, sortType: 'link', filter: null, visible: true},
  {key: 'rental_contract', name: 'Договор', maxWidth: 200, sortType: 'string', filter: null, visible: true},
  {key: 'unique_location_name', name: 'Урочище', maxWidth: 50, sortType: 'string', filter: null, visible: true},
  {
    key: 'district_forestry_name',
    name: 'Уч. лесничество',
    maxWidth: 200,
    sortType: 'string',
    filter: null,
    visible: true
  },
  {
    key: 'forestry_name',
    name: 'Лесничество',
    maxWidth: 200,
    sortType: 'string',
    filter: null,
    visible: true
  },
  {key: 'neighborhood', name: 'Район', maxWidth: 200, sortType: 'string', filter: null, visible: true},
  {key: 'region_name', name: 'Субъект', maxWidth: 200, sortType: 'string', filter: null, visible: true},
  {key: 'status_allocate', name: 'Статус отвода', maxWidth: 150, sortType: 'string', filter: null, visible: true},
  {key: 'who_allocate', name: 'Отвел (кто)', maxWidth: 100, sortType: 'string', filter: null, visible: true},
  {key: 'date_allocate', name: 'Дата отвода', maxWidth: 50, sortType: 'date', filter: null, visible: true},
  {
    key: 'felling_year_plan',
    name: 'Планируемый год рубок',
    maxWidth: 100,
    sortType: 'number',
    filter: null,
    visible: true
  },
  {
    key: 'felling_year_fact',
    name: 'Год фактической рубки',
    maxWidth: 120,
    sortType: 'number',
    filter: null,
    visible: true
  },
  {
    key: 'restorations_plan',
    name: 'Мероприятия по лесовосстановлению',
    maxWidth: 300,
    sortType: 'string',
    filter: null,
    visible: true
  },
  {
    key: 'year_restorations',
    name: 'Год проведения леcовостановления',
    maxWidth: 120,
    sortType: 'number',
    filter: null,
    visible: true
  },
  {
    key: 'restorations_fact',
    name: 'Фактический (назначеный) тип лесовостановительного мероприятия',
    maxWidth: 300,
    sortType: 'string',
    filter: null,
    visible: true
  },
]

export const casDetailsDefaults = {
  selectedPlot: null,
  loading: false,
  loadingError: false,
  data: null,
}

export const casDataDefaults = {
  loading: false,
  loadingError: false,
  data: null, //Список всех делянов с бекенда. Сюда же подгружатся знания о мониторинге.
  filteredList: null, //Отфильтрованный список для отображения в большой таблице. Сортируется кнопками. Состоит из ссылок на data.
  visibleFields: structuredClone(casAllFieldsList), //видимые поля таблицы
  details: structuredClone(casDetailsDefaults),
  panelFlex: 0.4, //высота панели детальной
  sorting: {column: null, direction: null},
  settings: { //данные для окна выбора полей
    listLeft: [],
    listRight: [],
  },
  filtersLists: { //данные для окна настройки фильтров
    /**
     * @type {{id: Number, name: string, fields: {}[]}[]}
     */
    list: null,
    currentFilter: null, //указатель на текущий редактируемый фильтр из list либо новый (без id)
  },
  selected: null,
}

export const declarationAllFieldsList = [
  {key: 'name', name: 'Реквизиты декларации', maxWidth: 200, sortType: 'string', filter: null, visible: true},
  {key: 'number', name: 'Лесная декларация <N>', maxWidth: 100, sortType: 'number', filter: null, visible: true},
  {key: 'date_at', name: 'Лесная декларация <дата>', maxWidth: 80, sortType: 'datestring', filter: null, visible: true},
  {
    key: 'date_start',
    name: 'Декларируемый период <с>',
    maxWidth: 100,
    sortType: 'datestring',
    filter: null,
    visible: true
  },
  {
    key: 'date_end',
    name: 'Декларируемый период <по>',
    maxWidth: 100,
    sortType: 'datestring',
    filter: null,
    visible: true
  },
  {
    key: 'executive_authority',
    name: 'Наименование органа государственной власти, органа местного самоуправления',
    maxWidth: 300,
    sortType: 'string',
    filter: null,
    visible: true
  },
  {
    key: 'juridical_person',
    name: 'Информация о лесопользователе <Наименование>',
    maxWidth: 300,
    sortType: 'string',
    filter: null,
    visible: true,
  },
  {
    key: 'juridical_person_inn',
    name: 'Информация о лесопользователе <ИНН>',
    maxWidth: 200,
    sortType: 'string',
    filter: null,
    visible: true,
  },
  {
    key: 'signer_data_fio',
    name: 'Информация о лице, подавшем лесную декларацию <ФИО>',
    maxWidth: 220,
    sortType: 'string',
    filter: null,
    visible: true
  },
  {
    key: 'signer_data_post',
    name: 'Информация о лице, подавшем лесную декларацию <должность>',
    maxWidth: 200,
    sortType: 'string',
    filter: null,
    visible: true
  },
  {
    key: 'rental_contract_type_document_1c',
    name: 'Правоустанавливающий документ <вид документа>',
    maxWidth: 200,
    sortType: 'string',
    filter: null,
    visible: true
  },
  {
    key: 'rental_contract_date_at',
    name: 'Правоустанавливающий документ <дата>',
    maxWidth: 220,
    sortType: 'datestring',
    filter: null,
    visible: true
  },
  {key: 'comment', name: 'Комментарий', maxWidth: 220, sortType: 'string', filter: null, visible: true}, //непонятно
  {
    key: 'declaration_xml_file',
    name: 'Документ загруженный',
    maxWidth: 80,
    sortType: 'link',
    text: 'Скачать',
    linkField: 'declaration_xml_file',
    filter: null,
    visible: true
  }, //непронятно
]

export const declarationDetailsDefaults = {
  selectedDeclaration: null,
  loading: false,
  loadingError: false,
  data: null,
}

export const declarationDataDefaults = {
  loading: false,
  loadingError: false,
  data: null, //Список всех деклараций
  filteredList: null, //Отфильтрованный список для отображения в большой таблице. Сортируется кнопками. Состоит из ссылок на data.
  visibleFields: structuredClone(declarationAllFieldsList), //видимые поля таблицы
  details: structuredClone(declarationDetailsDefaults),
  panelFlex: 0.4, //высота панели детальной
  sorting: {column: null, direction: null},
  settings: { //данные для окна выбора полей
    listLeft: [],
    listRight: [],
    show: false,
  },
  filtersLists: { //данные для окна настройки фильтров
    show: false,
    /**
     * @type {{id: Number, name: string, fields: {}[]}[]}
     */
    list: null,
    currentFilter: null, //указатель на текущий редактируемый фильтр из list либо новый (без id)
  },
  selected: null,
  goToItemID: null, //перейти сюда, если есть в списке. удаляется после перехода/проверки
}

export const defaultState = {
  open: false,
  curTab: "cas",
  cas: structuredClone(casDataDefaults),
  declarations: structuredClone(declarationDataDefaults),
  tableSettingsShow: false,
  filterSettingsShow: false,
}

export function setFullInfoData(payload) {
  return {
    type: 'CAS_FULL_INFO_SET_DATA',
    payload: payload,
  }
}

export function setFullInfoCasData(payload) {
  return {
    type: 'CAS_FULL_INFO_SET_CAS_DATA',
    payload: payload,
  }
}

export function setFullInfoCasDetailsData(payload) {
  return {
    type: 'CAS_FULL_INFO_SET_CAS_DETAILS_DATA',
    payload: payload,
  }
}

export function refreshFullInfoCasList() {
  return {
    type: 'CAS_FULL_INFO_REFRESH_CAS_LIST'
  }
}

export function setFullInfoDeclarationsData(payload) {
  return {
    type: 'CAS_FULL_INFO_SET_DECLARATIONS_DATA',
    payload: payload,
  }
}

export function setFullInfoDeclarationDetailsData(payload) {
  return {
    type: 'CAS_FULL_INFO_SET_DECLARATION_DETAILS_DATA',
    payload: payload,
  }
}

export function fullInfoReducer(state = defaultState, action) {
  switch (action.type) {
    case 'CAS_FULL_INFO_SET_DATA':
      return {...state, ...action.payload}
    case 'CAS_FULL_INFO_REFRESH_CAS_LIST':
      return {...state, cas: {...state.cas}}
    case 'CAS_FULL_INFO_SET_CAS_DATA':
      return {...state, cas: {...state.cas, ...action.payload}}
    case 'CAS_FULL_INFO_SET_CAS_DETAILS_DATA':
      return {...state, cas: {...state.cas, details: {...state.cas.details, ...action.payload}}}
    case 'CAS_FULL_INFO_SET_DECLARATIONS_DATA':
      return {...state, declarations: {...state.declarations, ...action.payload}}
    case 'CAS_FULL_INFO_SET_DECLARATION_DETAILS_DATA':
      return {
        ...state,
        declarations: {...state.declarations, details: {...state.declarations.details, ...action.payload}}
      }
    default:
      return state;
  }
}
