import {otherDataAPI} from "../../api/api";
import {createShapeFromGPX} from "../../components/Main/LeftPanel/Context/Projects/ProjectsCommon";
import {changeCA, createCAFromPolygon} from "../../components/Main/LeftPanel/Context/CAs/CuttingsAreas";
import {setSnack} from "../../components/Main/Map/Common/Dialog/Snack/snackReducer";
import {handleErrors} from "../commonReducerFunctions/ThunkErrorsHandler";
import {getPlotDataThunk} from "./rightPanelReducer";
import {updateAllCasFullInfo} from "../../components/Main/LeftPanel/Context/FullInfo/CAS/FullInfoCasTab";

const SET_PLOT_DATA_ATTR = 'SET_PLOT_DATA_ATTR'

export const defaultState = {
  // project
  selectedProjectTreeNode: null,

  // Деляна.
  showCreatePlotModal: false,
  showUpdatePlotModal: false,
  selectedPlot: null,

  // Склад.
  showCreateWarehouseModal: false,
  showUpdateWarehouseModal: false,
  selectedWarehouse: null,

  // gpx
  showUploadGpxModal: false,
  selectedCollection: null,
}

// редьюсер модального окна создания деляны
export const plotDataReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_PLOT_DATA_ATTR: {
      return {...state, ...action.payload};
    }
    default:
      return state
  }
}

export const setPlotDataAttr = (payload) => ({type: SET_PLOT_DATA_ATTR, payload});

export const uploadGpxFileThunk = (gpxFile, figureType, collection) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append(`gpx`, gpxFile);
      formData.append("geom_type", figureType);
      let res = await otherDataAPI.plot.uploadGpx(formData)
      createShapeFromGPX(res.data, collection, gpxFile.name)
      dispatch(setPlotDataAttr({showUploadGpxModal: false}))
      dispatch(setSnack('success', 'Координаты gpx загружены'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const CreatePlotThunk = (plotObj, selectedProjectTreeNode) => {
  return async (dispatch) => {
    try {
      let res = await otherDataAPI.plot.createPlot(plotObj)
      createCAFromPolygon(res.data, selectedProjectTreeNode)
      updateAllCasFullInfo()
      dispatch(setPlotDataAttr({showCreatePlotModal: false}))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const updatePlotThunk = (plotObj, plotId) => {
  return async (dispatch, getState) => {
    try {
      let res = await otherDataAPI.plot.updatePlot(plotObj, plotId)
      changeCA(res, plotId)
      dispatch(setPlotDataAttr({
        showUpdatePlotModal: false,
        selectedPlot: null
      }))
      // обновляем данные деляны в правой панели если они там есть
      getState().rightPanelReducer.plotData && dispatch(getPlotDataThunk(plotId))
      dispatch(setSnack('success', 'Данные деляны изменены'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}
