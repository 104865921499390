import {useSelector} from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {dispatch} from "../../../../../Common/misc_functions";
import {setFullInfoData} from "../fullInfoReducer";
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import {activeFilters} from "../../../../Map/Common/Strings_RU";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {ReactComponent as PolygonIcon} from '../../../../Map/Common/svg/polygon.svg';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

export function FiltersListsDialog() {
  const state = useSelector(state => state.fullInfoReducer.cas.filtersLists)
  const theme = useTheme()

  /*useEffect(() => {
    if (!state.currentFilter) {
      const fields = window.store.getState().casFullInfoReducer.cas.visibleFields;
      //console.log(fields)
      fields.forEach(field => {
        //console.log(field.filter)
      })
    }
  })*/

  return (
    <Dialog open={true}>
      <DialogTitle>
        <Stack direction={'row'} alignItems={'center'}>
          <span style={{flex: 1}}>Фильтры</span>
          <IconButton onClick={() => {
            dispatch(setFullInfoData({filterSettingsShow: false}))
          }}
          >
            <CloseOutlinedIcon/>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography>Сохраненные фильтры</Typography>
        <Stack direction={'row'} alignItems={'center'} gap={2} sx={{pt: 0.5}}>
          <FormControl size="small">
            <InputLabel id={'filter-dialog-actives-id'}>{activeFilters}</InputLabel>
            <Select
              variant={'outlined'}
              label={activeFilters}
              labelId={'filter-dialog-actives-id'}
              sx={{minWidth: '300px'}}
              value={state.currentFilter || ''}
            >
              {state.list && state.list.map(item =>
                <MenuItem value={item.id}>{item.name}</MenuItem>
              )}
            </Select>
          </FormControl>
          <IconButton size={'small'} title={'Эта кнопка что-то делает'}>
            <StarOutlineOutlinedIcon/>
          </IconButton>
        </Stack>
        <Button variant={'outlined'} size={'small'} sx={{mt: 1, mb: 2}}>
          Очистить
        </Button>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Typography sx={{flex: 1}}>{state.currentFilter?.name || 'Фильтр'}</Typography>
          <IconButton size='small' title={'Эта кнопка что-то делает'}>
            <StarOutlineOutlinedIcon/>
          </IconButton>
          <IconButton size='small' title={'Эта кнопка что-то делает'}>
            <SaveOutlinedIcon/>
          </IconButton>
          <Divider orientation={'vertical'} flexItem/>
          <IconButton size='small' title={'Эта кнопка что-то делает'}>
            <PolygonIcon style={{minWidth: '24px', stroke: theme.palette.primary.main}} fontSize={'small'}/>
          </IconButton>
        </Stack>
        <Stack direction={'row'} gap={2} sx={{mt: 0.5}}>
          <Button
            variant={'outlined'}
            size={'small'}
            sx={{flex: 0.5}}
          >
            Добавить поле
            <AddOutlinedIcon sx={{ml: 1}} fontSize={'small'}/>
          </Button>
          <Button
            variant={'outlined'}
            size={'small'}
            sx={{flex: 0.5}}
          >
            Очистить все
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
