import {useEffect, useState} from "react";
import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItemButton,
  Popover,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {searchString} from "../Main/Map/Common/Strings_RU";
import {Loading} from "../Main/Map/Common/Loading";

const emptyString = "пусто (как это назвать?)";

export function createFilterList(data, propName) {
  const arr = []
  let empty = false;
  data.forEach(plot => { //можно через reduce, но так быстрее
    const prop = plot[propName]
    if (prop) {
      if (!arr.find(item => item.name === prop)) {
        arr.push({name: prop, empty: false, checked: true})
      }
    } else {
      empty = true;
    }
  })
  arr.sort((a, b) => {
    if (a.name > b.name)
      return 1;
    else if (a.name < b.name)
      return -1;
    else return 0;
  })
  if (empty) {
    arr.unshift({name: emptyString, empty: true, checked: true})
  }
  return arr;
}

/**
 * Всплывающее меню фильтра
 * @param data {[]}- массив всех элементов (для создания списка фильтрации)
 * @param field {{key: string, name: string, checked: boolean, filter: []}} - текущее поле
 * @param menuAnchor - элемент привязки меню
 * @param filterClose - callback закрытия
 */
export function FilterPopup({data, field, menuAnchor, filterClose}) {
  const [list, setList] = useState(structuredClone(field.filter)) //копия для изменений, меняется тыками
  const [showList, setShowList] = useState(list) //копия для отображения, меняется поиком
  const [allChecked, setAllChecked] = useState(false)

  useEffect(() => {
    if (!field.filter && !list) {
      const arr = createFilterList(data, field.key)
      //field.filter = arr;
      setList(arr)
      setShowList(arr)
      checkAllChecked()
    }
  }, [list, field.filter])

  function itemClick(item) {
    item.checked = !item.checked;
    setList([...list])
    checkAllChecked()
  }

  function changeAllChecked() {
    const checked = !allChecked;
    list.forEach(item => item.checked = checked)
    setAllChecked(checked)
  }

  function checkAllChecked() {
    const res = !list?.some(item => !item.checked)
    setAllChecked(res)
  }

  function permitFilter() {
    //TODO если поставлены все, убираем фильтр
    if (allChecked) {
      field.filter = null;
    } else {
      field.filter = list;
    }
    //TODO проверить изменилось ли чего, если нет - filterClose(null)
    filterClose(true)
  }

  function filterCancel() {
    field.filter = null;
    filterClose(true)
  }

  function searchHandler(event) {
    const value = event.target.value;
    const arr = list.filter(item => {
      return item.name.toString().toUpperCase().includes(value.toUpperCase())
    })
    setShowList(arr)
  }

  return (
    <Popover
      open={true}
      anchorEl={menuAnchor}
      onClose={() => filterClose(false)}
      disableAutoFocus
      disableEnforceFocus
    >
      <Box sx={{p: 1}}>
        <TextField
          fullWidth
          size={'small'}
          label={searchString}
          onChange={searchHandler}
        />
      </Box>
      <Divider/>
      <List sx={{maxHeight: '30vh', maxWidth: '40vw', m: 1, overflow: 'auto'}}>
        {showList && list
          ?
          <>
            {showList.length !== 0
              ?
              showList.map((item, index) => {
                //TODO Виртуализовать список!
                return (
                  <ListItemButton key={index} disableRipple disableGutters>
                    <FormControlLabel
                      sx={{width: '100%'}}
                      control={<Checkbox size={'small'} checked={item.checked} onChange={() => itemClick(item)}/>}
                      label={item.name}
                    />
                  </ListItemButton>
                )
              })
              :
              <Typography>Не найдено подходящих элементов</Typography>
            }
          </>
          :
          <Loading/>
        }
      </List>
      <Divider/>
      <Stack direction={'row'} sx={{p: 1}}>
        <FormControlLabel
          sx={{width: '100%'}}
          control={<Checkbox size={'small'} checked={allChecked} onChange={changeAllChecked}/>}
          label={'Все позиции'}
        />
        <IconButton
          color={'warning'}
          onClick={() => filterCancel()}
          title={'Отменить фильтр'}
        >
          <CloseOutlinedIcon/>
        </IconButton>
        <IconButton
          color={'info'}
          onClick={permitFilter}
          title={'Применить фильтр'}
        >
          <CheckOutlinedIcon/>
        </IconButton>
      </Stack>
    </Popover>
  )
}
