// Initial State
const DEFAULT_PANEL_FLEX = 0.3;
const defaultState = {
  curTab: '', //текущая кладка
  panelFlex: DEFAULT_PANEL_FLEX, //текуший размер панели
  panelFullWidth: false,
  //panelFlexPrev: DEFAULT_PANEL_FLEX,
}

// Reducer
export function leftDrawerReducer(state = defaultState, action) {
  switch (action.type) {
    case 'SET_LEFT_DRAWER_CUR_TAB':
      return {
        ...state,
        curTab: action.tab,
      }
    case 'SET_LEFT_DRAWER_DATA':
      return {...state, ...action.data}
    default:
      return state;
  }
}

export const setLeftDrawerCurTab = (tab) => ({type: 'SET_LEFT_DRAWER_CUR_TAB', tab})
export const setLeftDrawerData = (data) => ({type: 'SET_LEFT_DRAWER_DATA', data})
