import {Autocomplete, Button, Stack} from "@mui/material";
import {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {emptyDeclarationState, setDeclarationXMLAttr} from "../cuttingAreasReducer";
import {Loading} from "../../../../Map/Common/Loading";
import {ErrorAlert} from "../../../../../Common/ErrorAlert";
import {ModalDialog} from "../../../../../Common/ModalDialog";
import TextField from "@mui/material/TextField";
import {dataAPI} from "../../../../../../api/api";
import {handleErrors} from "../../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {createCA} from "../CuttingsAreas";
import {refresh as refreshFilters} from "../../../../Map/Filters/FiltersReducer";
import {refreshSearchResults} from "../SearchResultsReducer";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";
import {updateAllCasFullInfo} from "../../FullInfo/CAS/FullInfoCasTab";

export function DeclarationLoadingDialog({state, onClose}) {
  const dispatch = useDispatch()
  /**
   * @type {React.MutableRefObject<object>}
   */
  const inputRef = useRef()

  useEffect(() => {
    if (!state.loading && !state.workspaces && !state.loadingError) {
      dispatch(setDeclarationXMLAttr({loading: true}))
      dataAPI.plots.getDeclarationAvaibleWorkspaces()
        .then(res => {
          dispatch(setDeclarationXMLAttr({loading: false, loadingError: false, workspaces: res.data}))
        })
        .catch(() => {
          dispatch(setDeclarationXMLAttr({loading: false, loadingError: true}))
        })
    }
  }, [state])

  function handleSave() {
    if (validateFrom()) {
      const data = new FormData()
      data.append('rental_contract_id', state.workspace.id)
      data.append('name', state.name)
      data.append('xml_file', state.file)
      dataAPI.plots.createCAsFromDeclarationXML(data)
        .then(res => {
          res.data.forEach((feature) => createCA(feature))
          updateAllCasFullInfo()
          dispatch(refreshFilters())
          dispatch(refreshSearchResults())
          dispatch(setDeclarationXMLAttr({...emptyDeclarationState}))
          dispatch(setSnack('success', 'Декларация создана.'))
        })
        .catch(err => {
          handleErrors(dispatch, err)
        })
    }
  }

  function validateFrom() {
    const wpE = !!state.workspace;
    const nE = !!state.name;
    const fE = !!state.file;
    return wpE && nE && fE;
  }

  function handleInputChange(event) {
    const file = event.target.files[0]
    dispatch(setDeclarationXMLAttr({file: file || null}))
  }

  const disableForm = state.loading || state.loadingError;

  return (
    <ModalDialog
      title={'Загрузка декларации из xml-файла'}
      onClose={onClose}
      onSave={handleSave}
      saveButtonName={'Загрузить'}
      saveButtonDisabled={!validateFrom()}
    >
      {state.loading && <Loading style={{marginBottom: '2vh'}}/>}
      {state.loadingError &&
        <ErrorAlert
          text={'Произошла ошибка при загрузке информации с сервера. Нажмите кнопку ниже, чтобы попробовать еще раз.'}
          onClick={() =>
            dispatch(setDeclarationXMLAttr({...emptyDeclarationState, dialogOpen: true}))
          }
        />
      }
      <Stack gap={1.5}>
        <Autocomplete
          size={'small'}
          disabled={disableForm}
          options={state.workspaces || []}
          value={state.workspace}
          getOptionLabel={(w) => w.name}
          getOptionKey={(w) => w.id}
          onChange={(e, value) =>
            dispatch(setDeclarationXMLAttr({workspace: value}))
          }
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                error={state.workspaceError}
                label={'Рабочая область/Договор аренды'}
              />
            )
          }}
        />
        <TextField
          value={state.name}
          error={state.nameError}
          disabled={disableForm}
          size={'small'}
          style={{width: '100%'}}
          label={'Наименование декларации'}
          onChange={e => dispatch(setDeclarationXMLAttr({name: e.target.value}))}
        />
        <Stack direction={'row'} gap={1}>
          <TextField
            value={state.file?.name || ''}
            error={state.fileError}
            label={'Имя файла'}
            disabled={true}
            size={'small'}
            style={{flex: 1}}
            inputProps={{ maxLength: 100 }}
          />
          <Button
            variant={'outlined'}
            disabled={disableForm}
            onClick={() => inputRef.current.click()}
          >
            Выбрать XML
          </Button>
          <input
            hidden
            accept="text/xml"
            type="file"
            ref={inputRef}
            onChange={handleInputChange}
          />
        </Stack>
      </Stack>
    </ModalDialog>
  )
}
