import {IconButton, Menu, MenuItem, useTheme} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, {useState} from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {treeItemButtonIconSize} from "../../../Map/Common/Styles";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {setPlotDataAttr} from "../../../../../redux/reducers/plotDataReducer";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {hideMapDialog, showMapDialog} from "../../../Map/Common/Dialog/DialogReducer";
import {
  confirmDeleteCA,
  coordinatesString,
  deleteString,
  deletingCA,
  deletingError,
  deletingSuccess,
  editString
} from "../../../Map/Common/Strings_RU";
import {dataAPI} from "../../../../../api/api";
import {deleteCA, getCuttingAreas, setCANewCoordinates} from "./CuttingsAreas";
import {setSnack} from "../../../Map/Common/Dialog/Snack/snackReducer";
import {djangoErrorsParse} from "../../../../../utils/djangoErrorsParse";
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import {showCoordinatesEditor} from "../../../Map/Common/Dialog/CoordinatesEditor/coordinatesEditorReducer";

export function CAContextMenu(props) { // контекстное меню деляны
  const items = useSelector(state => state.searchResultsReducer.searchResults)
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);
  const dispatch = useDispatch()
  let layer = props.ca;
  if (!layer.elz_properties) { //FIXME Если удаление идет из большого делянового окна. Удалить после перехода к новому виду делянов.
    layer = getCuttingAreas().layerArray.find(layer => layer.elz_properties.id === props.ca.id)
  }
  const theme = useTheme()

  const deleteCAHandler = (layer) => { //удаление деляны с диалогом
    const buttons = {'Да': {color: theme.palette.warning.main}, 'Нет': {color: theme.palette.primary.main}}
    dispatch(showMapDialog('warning', deletingCA, confirmDeleteCA, buttons, (ee) => {
      dispatch(hideMapDialog())
      if (ee === 'Да') {
        dataAPI.plots.delete(layer.elz_properties.id).then(res => {
          if (res.status === 204) {
            deleteCA(layer, items)
            dispatch(setSnack('success', deletingSuccess))
          } else {
            dispatch(setSnack('error', deletingError))
          }
        }).catch(err => {
          console.error(err.message)
          dispatch(setSnack('error', err.response?.data ? djangoErrorsParse(err.response.data) : deletingError))
        })
      }
    }))
    setMenuAnchor(null)
  }

  return (
    <>
      <IconButton
        disableRipple
        style={{padding: 0}}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setMenuAnchor(e.currentTarget)
        }}
      >
        <MoreVertIcon fontSize={'small'}/>
      </IconButton>
      {menuAnchor &&
      <Menu
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={(e) => {
          e.stopPropagation()
          setMenuAnchor(null)
        }}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setMenuAnchor(null)
            dispatch(setPlotDataAttr({showUpdatePlotModal: true, selectedPlot: layer.elz_properties}))
          }}
        >
          <EditOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>{editString}</Typography>
        </MenuItem>
        {layer.elz_properties.status !== 'Закрыта' && layer.elz_properties.status !== 'В архиве' &&
          <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setMenuAnchor(null)
            dispatch(
              showCoordinatesEditor(
                layer.getLatLngs()[0],
                "Polygon",
                (latlngs) => {
                  setCANewCoordinates(layer, latlngs)
                },
                layer.elz_properties.name))
          }}
        >
          <RoomOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>{coordinatesString}</Typography>
        </MenuItem>
        }
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setMenuAnchor(null)
            deleteCAHandler(layer)
          }}
        >
          <DeleteOutlineOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>{deleteString}</Typography>
        </MenuItem>
      </Menu>
      }
    </>
  )
}
