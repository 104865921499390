import {IconButton} from "@mui/material";
import {SortingIcon} from "../Common/SortingIcon/SortingIcon";
import {FilterHeaderButton} from "./FilterHeaderButton";

export function TableHeaderButtons({hide, data, field, filterClose, sorting, sortingButtonClick}) {
  //const sorting = window.store.getState().casFullInfoReducer.cas.sorting;

  let iconStyle = {
    height: '16px',
    width: '16px',
  }

  const hidedStyle = {
    pointerEvents: 'none',
    fill: 'transparent',
    stroke: 'transparent',
  }

  let sortStyle = {...iconStyle}
  let filterStyle = {...iconStyle}

  if (hide && sorting.column !== field.key && !field.filter) {
    sortStyle = {...iconStyle, ...hidedStyle}
  }

  if (hide && !field.filter) {
    filterStyle = {...iconStyle, ...hidedStyle}
  }

  let mode = null;
  if (field.key === sorting.column) {
    mode = sorting.direction === 1;
  }

  return (
    <>
      <IconButton disableRipple style={{...sortStyle, position: 'inherit'}} onClick={sortingButtonClick}>
        <SortingIcon style={sortStyle} mode={mode}/>
      </IconButton>
      <FilterHeaderButton data={data} field={field} style={{...filterStyle, position: 'inherit'}}
                          filterClose={filterClose}/>
    </>
  )
}
