import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import {useState} from "react";
import {SortableList} from "./SortableList";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

export function FieldChooser({listLeft, listRight, onCommit}) {

  const [settings, setSettings] = useState({listLeft: listLeft, listRight: listRight})

  function listItemButtonClick(data) {
    const isInLeft = settings.listLeft.find(item => data === item)
    let arrLeft = [...settings.listLeft]
    let arrRight = [...settings.listRight]

    if (isInLeft) {
      arrLeft = settings.listLeft.filter(item => item !== data)
      arrRight.push(data)
    } else {
      arrRight = settings.listRight.filter(item => item !== data)
      arrLeft.push(data)
    }
    setSettings({listLeft: arrLeft, listRight: arrRight})
  }

  return (
    <Dialog open={true} maxWidth={'md'} fullWidth={true}>
      <DialogTitle>
        <Stack direction={'row'} alignItems={'center'}>
          <span style={{flex: 1}}>Настройка полей таблицы</span>
          <IconButton onClick={() => {
            onCommit(null)
            setSettings({listLeft: [], listRight: []})
          }}
          >
            <CloseOutlinedIcon/>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction={'row'} sx={{height: '50vh'}} gap={2}>
          <Stack sx={{flex: 0.5}}>
            <Typography sx={{fontWeight: 600}}>Поля таблицы</Typography>
            <div style={{height: '100%', overflowY: 'auto'}}>
              {settings.listLeft &&
                <SortableList
                  id={'left-sortable-list'}
                  items={settings.listLeft}
                  setItems={(items) => {
                    setSettings({...settings, listLeft: items})
                  }}
                  onButtonClick={listItemButtonClick}
                  buttonIcon={<RemoveOutlinedIcon/>}
                />
              }
            </div>
          </Stack>
          <Divider orientation={'vertical'}/>
          <Stack sx={{flex: 0.5}}>
            <Typography sx={{fontWeight: 600}}>Возможные поля</Typography>
            <div style={{height: '100%', overflowY: 'auto'}}>
              {settings.listLeft &&
                <SortableList
                  id={'right-sortable-list'}
                  items={settings.listRight}
                  setItems={(items) => {
                    setSettings({...settings, listRight: items})
                  }}
                  onButtonClick={listItemButtonClick}
                  buttonIcon={<AddOutlinedIcon color={'info'}/>}
                />
              }
            </div>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCommit(settings)}>
          Завершить
        </Button>
      </DialogActions>
    </Dialog>
  )
}
