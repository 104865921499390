import {useTheme} from "@mui/material";
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import NorthOutlinedIcon from '@mui/icons-material/NorthOutlined';
import SouthOutlinedIcon from '@mui/icons-material/SouthOutlined';

/**
 * Иконка сортировки.
 * @param mode - режим (вид) иконки: true - стрелка вниз, false - стрелка вверх, null - две стрелки
 * @param style {object} - дополнительные стили
 */
export function SortingIcon({mode = null, style = {}}) {
  const theme=useTheme()
  const iconStyle = {
    height: '16px',
    width: '16px',
    fill: mode === null ? theme.palette.text.secondary : theme.palette.primary.main,
    ...style,
  }

  if (mode === null) {
    return <SwapVertOutlinedIcon style={iconStyle}/>
  }

  if (mode === true) {
    return <NorthOutlinedIcon style={iconStyle}/>
  }

  if (mode === false) {
    return <SouthOutlinedIcon style={iconStyle}/>
  }

  return null;
}
