import {useEffect} from "react";
import {dataAPI} from "../../../../../../api/api";
import {loadingErrorWithRepeatText, sendToString} from "../../../../Map/Common/Strings_RU";
import {useDispatch, useSelector} from "react-redux";
import {sendToDialogDefaults, setSendShapeToDialog} from "../projectsReducer";
import {Loading} from "../../../../Map/Common/Loading";
import {ModalDialog} from "../../../../../Common/ModalDialog";
import {ErrorAlert} from "../../../../../Common/ErrorAlert";
import {Checkbox, Divider, List, ListItemButton, Stack, TextField, Typography,} from "@mui/material";
import {handleErrors} from "../../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";
import {postGraySpanStyle} from "../../../../Map/Common/Styles";

export function SendShapeToMobileDialog() {
  const state = useSelector(state => state.projectsReducer.sendToMobile)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!state.allUsers && !state.loading && !state.loadingError) {
      dispatch(setSendShapeToDialog({loading: true, loadingError: false}))
      dataAPI.organization.getAllUsersShort().then((res) => {
        dispatch(setSendShapeToDialog({
          allUsers: res.data.users,
          users: structuredClone(res.data.users),
          loading: false,
          loadingError: false
        }))
      }).catch(() => {
        dispatch(setSendShapeToDialog({users: null, loading: false, loadingError: true}))
      })
    }
  }, [state.users, state.loading, state.loadingError])

  function handleClose() {
    dispatch(setSendShapeToDialog(structuredClone(sendToDialogDefaults)))
  }

  function handleSave() {
    const json = {users_id: state.selectedUsers}
    dataAPI.projects.shapes.sendShapeToMobile(state.node.id, json)
      .then(() => {
        dispatch(setSnack('success', "Фигура успешно отправлена"))
        handleClose()
      })
      .catch((err) => {
        handleErrors(dispatch, err)
      })
  }

  function getSelectedUserIndex(user) {
    return state.selectedUsers.indexOf(user.id)
  }

  function changeUser(user) {
    const index = getSelectedUserIndex(user)
    if (index === -1) {
      state.selectedUsers.push(user.id)
    } else {
      state.selectedUsers.splice(index, 1)
    }
    dispatch(setSendShapeToDialog({selectedUsers: [...state.selectedUsers]}))
  }

  function filterUsers(value) {
    if (value !== '') {
      const arr = state.allUsers.filter(user => user["full_name"].toUpperCase().indexOf(value.toUpperCase()) !== -1)
      dispatch(setSendShapeToDialog({users: arr}))
    } else {
      dispatch(setSendShapeToDialog({users: structuredClone(state.allUsers)}))
    }
  }

  if (!state.node) {
    return null;
  }

  return (
    <ModalDialog
      title={'Отправить в мобильное приложение'}
      onClose={handleClose}
      onSave={handleSave}
      saveButtonName={sendToString}
      saveButtonDisabled={state.selectedUsers.length === 0}
    >
      {state.loading
        ?
        <Loading/>
        : state.loadingError
          ?
          <ErrorAlert text={loadingErrorWithRepeatText} onClick={() => {
            dispatch(setSendShapeToDialog({...structuredClone(sendToDialogDefaults), node: state.node}))
          }}
          />
          :
          <Stack spacing={2}>
            <TextField
              value={state.searchField}
              variant={'outlined'}
              label={'Поиск по фамилии'}
              size={'small'}
              onChange={(event) => {
                dispatch(setSendShapeToDialog({searchField: event.target.value}))
                filterUsers(event.target.value)
              }}
            />
            <Divider/>
            <List
              sx={{height: '30vh', overflow: 'auto'}}
            >
              {state.users && state.users.map(user => {
                return (
                <ListItemButton disableRipple key={user.id} sx={{pt: 0, pb: 0}} onClick={() => changeUser(user)}>
                  <Checkbox checked={getSelectedUserIndex(user) !== -1}/>
                  <Typography>
                    <span>{user["full_name"]}</span>
                    {user.post && <span style={postGraySpanStyle}>, {user.post}</span>}
                  </Typography>
                </ListItemButton>
                )
              })}
            </List>
          </Stack>
      }
    </ModalDialog>
  )
}
