import {useDispatch, useSelector} from "react-redux";
import {TableCell} from "@mui/material";
import {casDetailsDefaults, setFullInfoCasData, setFullInfoCasDetailsData, setFullInfoData} from "../fullInfoReducer";
import Box from "@mui/material/Box";
import {CasStatusIcons} from "./CasStatusIcons";
import {cannotIDo} from "../../../../Map/Common/tariffs";
import {getCuttingAreas} from "../../CAs/CuttingsAreas";
import {loadRightPanelData} from "../../../../RightPanel/right_panel";
import {flyToPolygon} from "../../../../Map/Common/fly";
import '../Common/common.css';
import {TableWithBellsAndWhistles} from "../../../../../TableWithBellsAndWhistles/TableWithBellsAndWhistles";
import {sortingCasTable, updateFilters} from "./FullInfoCasTab";
import {TableCellLink} from "../Common/TableCellLink";

function CasCell({plot, field, style, linkClick}) {
  const readOnly = cannotIDo.editorAction()

  if (field.sortType === 'link') {
    if (plot.declaration_id) { //сылка на декларацию
      return <TableCellLink item={plot} field={field} style={style} linkClick={linkClick} name={plot.declaration}/>
    }
  }

  return (
    <TableCell
      style={style}
      className={'ellipsis'}
    >
      {field.key === 'status'
        ?
        <CasStatusIcons plot={plot} readOnly={readOnly}/>
        :
        <span title={plot[field.key]}>{plot[field.key]}</span>
      }
    </TableCell>
  )
}

export function CasTable() {
  const data = useSelector(state => state.fullInfoReducer.cas.filteredList)
  const visibleFields = useSelector(state => state.fullInfoReducer.cas.visibleFields)
  const sorting = useSelector(state => state.fullInfoReducer.cas.sorting)
  const selected = useSelector(state => state.fullInfoReducer.cas.selected)
  const fullPanelWidth = useSelector(state => state.leftDrawerReducer.panelFullWidth)
  const dispatch = useDispatch()

  function tableRowClick(plot) {
    //const panelFullWidth = window.store.getState().fullInfoReducer.panelFullWidth;
    if (fullPanelWidth) {
      const selectedPlot = window.store.getState().fullInfoReducer.cas.details.selectedPlot;
      const selectionType = document.getSelection()?.type;
      if (!selectionType || selectionType !== 'Range') {
        if (selectedPlot !== plot) {
          dispatch(setFullInfoCasDetailsData({...casDetailsDefaults, selectedPlot: plot, data: null}))
        } else {
          dispatch(setFullInfoCasDetailsData(structuredClone(casDetailsDefaults)))
        }
      }
    } else {
      const layer = getCuttingAreas().layerArray.find(layer => layer.elz_properties.id === plot.id)
      if (layer) {
        loadRightPanelData('Plot', layer, layer.elz_properties.id, false)
        flyToPolygon(layer.getLatLngs())
      }
    }
    //setSelected(plot)
    dispatch(setFullInfoCasData({selected: plot}))
  }

  function linkClick(field, item) {
    const stateDecl = window.store.getState().fullInfoReducer.declarations;
    dispatch(setFullInfoData({
      curTab: 'declarations',
      declarations: {
        ...stateDecl,
        goToItemID: item.declaration_id,
      }
    }))
  }

  return (
    <Box style={{position: 'absolute', inset: 0, overflow: 'hiden'}}>
      <TableWithBellsAndWhistles
        data={data}
        visibleFields={visibleFields}
        getCell={(item, field, style) =>
          <CasCell key={field.key} plot={item} field={field} style={style} linkClick={linkClick}/>
        }
        rowClick={tableRowClick}
        sorting={sorting}
        fieldSorting={sortingCasTable}
        selected={selected}
        updateFilters={() => updateFilters()}
        stickyColumnsCount={fullPanelWidth ? 2 : 0}
      />
    </Box>
  )
}
