import {IconButton, Stack} from "@mui/material";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import {useDispatch, useSelector} from "react-redux";
import {DeclarationLoadingButton} from "./DeclarationLoadingButton";
import {casDetailsDefaults, setFullInfoCasData, setFullInfoData} from "../fullInfoReducer";
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import {setLeftDrawerData} from "../../../LeftDrawerReducer";

export function FullInfoButtons({}) {
  const panelFullWidth = useSelector(state => state.leftDrawerReducer.panelFullWidth)
  const dispatch = useDispatch()

  function setFullWidth() {
    if (panelFullWidth) { //отключаем деталку, если сворачивается
      dispatch(setFullInfoCasData({details: structuredClone(casDetailsDefaults)}))
    }
    dispatch(setLeftDrawerData({panelFullWidth: !panelFullWidth}))
  }

  return (
    <Stack direction={'row'} gap={0.3}>
      <DeclarationLoadingButton/>
      <IconButton
        //disabled={noCas}
        size={'small'}
        onClick={() => {
          dispatch(setFullInfoData({tableSettingsShow: true}))
        }}
      >
        <TuneOutlinedIcon/>
      </IconButton>
      <IconButton
        //disabled={noCas}
        size={'small'}
        onClick={() => {
          dispatch(setFullInfoData({filterSettingsShow: true}))
        }}
      >
        <FilterAltOutlinedIcon/>
      </IconButton>
      <IconButton
        size={'small'}
        onClick={setFullWidth}
      >
        <LastPageOutlinedIcon style={{transform: `${panelFullWidth ? 'rotate(180deg)' : 'none'}`}}/>
      </IconButton>
    </Stack>
  )
}
