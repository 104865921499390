import {IconButton, Menu, MenuItem, useTheme} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import {treeItemButtonIconSize, treeItemButtonStyle} from "../../../Map/Common/Styles";
import {DownloadFileButton} from "./DownloadFileButton";
import {refreshProjects, setSendShapeToDialog} from "./projectsReducer";
import {useDispatch} from "react-redux";
import {deleteNode, getProjects, isShapeNode} from "./ProjectsCommon";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {hideMapDialog, showMapDialog} from "../../../Map/Common/Dialog/DialogReducer";
import {
  deleteFolderString,
  deleteFolderTest,
  deleteShapeString,
  deleteShapeText,
  deleteString,
  functionalIsNotRelized,
  renameString,
  saveGeoJSONString,
  saveGPXTrackString,
  saveString,
  sendToString,
  settingsString,
} from "../../../Map/Common/Strings_RU";
import {saveGPXTrackFile} from "../../../Map/Common/Exports/gpx";
import {setSnack} from "../../../Map/Common/Dialog/Snack/snackReducer";
import {saveGEOJsonFeatureCollection} from "../../../Map/Common/Exports/geojson";
import {NestedMenuItem} from "mui-nested-menu";
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import {
  openWorkspaceSettingsDialog,
  resetTransportMonitoringDataThunk,
  updateTransportMonitoringDataThunk
} from "./WorkspaceSettings/workspaceReducer";
import SecurityUpdateOutlinedIcon from '@mui/icons-material/SecurityUpdateOutlined';

export function ProjectsItemContextMenu(props) {
  const [menuAnchor, setMenuAnchor] = useState(null)
  const menuOpen = Boolean(menuAnchor);
  const node = props.node;
  const dispatch = useDispatch()
  const theme = useTheme()

  function sendShapeToMobile() {
    dispatch(setSendShapeToDialog({node: node}))
  }

  function startEditNode(node) {
    const projects = getProjects()
    projects.editingNode = node;
    node.edit = 2;
    dispatch(refreshProjects())
  }

  function deleteShape(node) {
    //TODO Сделать как в попках
    const buttons = {'Да': {color: 'error'}, 'Нет': {color: 'primary'}}
    dispatch(showMapDialog('warning', deleteShapeString, deleteShapeText.replace('__SHAPENAME__', node.name), buttons, (result) => {
      dispatch(hideMapDialog())
      if (result === 'Да') {
        deleteNode(node)
      }
    }))
  }

  function deleteFolder(node) {
    const buttons = {'Да': {color: theme.palette.warning.main}, 'Нет': {color: theme.palette.success.main}}
    dispatch(showMapDialog('warning', deleteFolderString, deleteFolderTest, buttons, (result) => {
      dispatch(hideMapDialog())
      if (result === 'Да') {
        deleteNode(node)
      }
    }))
  }

  function handleDownloads(type) {
    //setState({...state, anchor: null})
    switch (type) {
      case 'GPX':
        saveGPXTrackFile(node)
        break
      case 'GEOJSON':
        saveGEOJsonFeatureCollection(node)
        break;
      case 'KML':
        dispatch(setSnack('warning', functionalIsNotRelized))
        break;
    }
  }

  const deleteDisabled = !node.id || !node.new;

  return (
    <>
      <IconButton
        disableRipple
        style={{...treeItemButtonStyle, padding: 0}}
        onClick={(e) => {
          setMenuAnchor(e.currentTarget)
        }}
      >
        <MoreVertIcon fontSize={'small'} style={treeItemButtonIconSize}/>
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        {node.type === 'workspace' &&
          <MenuItem
            onClick={() => {
              setMenuAnchor(null)
              dispatch(openWorkspaceSettingsDialog(node, false))
            }}
          >
            <SettingsSuggestOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
            <Typography>{settingsString}</Typography>
          </MenuItem>
        }
        {node.type === 'workspace' &&
          <MenuItem
            onClick={() => {
              setMenuAnchor(null)
              dispatch(updateTransportMonitoringDataThunk(node))
            }}
          >
            <MultipleStopIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
            <Typography>Обновить данные мониторинга</Typography>
          </MenuItem>
        }
        {node.type === 'workspace' &&
          <MenuItem
            onClick={() => {
              setMenuAnchor(null)
              dispatch(resetTransportMonitoringDataThunk(node))
            }}
          >
            <RestartAltIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
            <Typography>Сбросить данные мониторинга</Typography>
          </MenuItem>
        }
        <MenuItem
          onClick={() => {
            startEditNode(node)
          }}
        >
          <EditOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>{renameString}</Typography>
        </MenuItem>
        {isShapeNode(node) &&
          <MenuItem
            onClick={() => {
              sendShapeToMobile()
            }}
          >
            <SecurityUpdateOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
            <Typography>{sendToString} (beta)</Typography>
          </MenuItem>
        }
        {node.type !== 'workspace' &&
          <NestedMenuItem
            leftIcon={<DownloadFileButton/>}
            label={saveString}
            parentMenuOpen={menuOpen}
            style={{paddingLeft: '12px'}}
          >
            <MenuItem onClick={() => handleDownloads('GPX')}>
              <Typography>{saveGPXTrackString}</Typography>
            </MenuItem>
            <MenuItem onClick={() => handleDownloads('GEOJSON')}>
              <Typography>{saveGeoJSONString}</Typography>
            </MenuItem>
          </NestedMenuItem>
        }
        {node.type !== 'workspace' &&
          <MenuItem
            disabled={!deleteDisabled}
            onClick={() => {
              setMenuAnchor(null);
              if (isShapeNode(node))
                deleteShape(node)
              else if (node.type === 'folder')
                deleteFolder(node)
              /*              else if (node.type === 'workspace')
                              deleteWorkspace(node)*/
            }}
          >
            <DeleteOutlineOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
            <Typography>{deleteString}</Typography>
          </MenuItem>
        }
      </Menu>
    </>
  )
}

//sendToString
