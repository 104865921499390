import {Box} from "@mui/material";
import {useEffect, useState} from "react";

//Почему-то в полной панели происходит смещение на пихель вправо и вниз.
//Почему непонятно :(. Пришлось делать shift и fullPanel

export function LeftPanelButtonDump({index, fullPanel = false}) {
  const buttonRef = document.getElementById(`lb_button_${index}`)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => { //Обновляем, если кнопка не успела нарисоваться. Например при выходе из ЛК.
    if (!buttonRef) {
      setRefresh(!refresh)
    }
  })

  if (!buttonRef) {
    return null;
  }

  let shift = 0;
  if (fullPanel) {
    shift = -1;
  }

  return (
    <Box
      style={{
        position: 'absolute',
        top: buttonRef.offsetTop + shift + 1, //начало на пихель ниже бордюра
        left: shift,
        backgroundColor: 'white',
        zIndex: 901,
        width: 1,
        height: buttonRef.getBoundingClientRect().height - 2, //вычитаем бордюр
        boxSizing: 'border-box',
      }}
    >
    </Box>
  )
}
