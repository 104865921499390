import {IconButton, Stack, Tab, Tabs} from "@mui/material";
import {LeftPanelButtonDump} from "../../LeftPanelButtonDump";
import {useDispatch, useSelector} from "react-redux";
import {casString, contractsString, declarationsString} from "../../../Map/Common/Strings_RU";
import {setFullInfoCasData, setFullInfoData, setFullInfoDeclarationsData} from "./fullInfoReducer";
import {FullInfoCasTab, updateFilters as updateCASFilters} from "./CAS/FullInfoCasTab";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {setLeftDrawerCurTab} from "../../LeftDrawerReducer";
import {FullInfoButtons} from "./TopPanelButtons/FullInfoButtons";
import {FullInfoDelarationsTab} from "./Declarations/FullInfoDelarationsTab";
import {FieldChooser} from "../../../../Common/FieldChooser";
import {FiltersListsDialog} from "./CAS/FiltersListsDialog";
import {updateDeclarationsFilters} from "./Declarations/DeclarationTable";

export function FullInfo({}) {
  const curTab = useSelector(state => state.fullInfoReducer.curTab)
  const tableFieldsSettings = useSelector(state => state.fullInfoReducer.tableSettingsShow)
  const filterSettings = useSelector(state => state.fullInfoReducer.filterSettingsShow)
  const dispatch = useDispatch()

  function handleChange(event, value) {
    dispatch(setFullInfoData({curTab: value}))
  }

  function settingsCommit(data) {
    if (curTab === 'cas') {
      const state = window.store.getState().fullInfoReducer.cas;
      if (data === null) { //отмена, просто закрывает диалог
        dispatch(setFullInfoData({tableSettingsShow: false}))
        return;
      }
      data.listRight.forEach(item => {
        item.filter = null;
      })
      const arr = [...data.listLeft]
      arr.unshift(state.visibleFields[1])
      arr.unshift(state.visibleFields[0])
      dispatch(setFullInfoCasData({
        visibleFields: arr,
        settings: {listLeft: arr, listRight: data.listRight}
      }))
      dispatch(setFullInfoData({tableSettingsShow: false}))
      updateCASFilters()
    }

    if (curTab === 'declarations') {
      const state = window.store.getState().fullInfoReducer.declarations;
      if (data === null) { //отмена, просто закрывает диалог
        dispatch(setFullInfoData({tableSettingsShow: false}))
        return;
      }
      data.listRight.forEach(item => {
        item.filter = null;
      })
      const arr = [...data.listLeft]
      arr.unshift(state.visibleFields[0])
      dispatch(setFullInfoDeclarationsData({
        visibleFields: arr,
        settings: {listLeft: arr, listRight: data.listRight}
      }))
      dispatch(setFullInfoData({tableSettingsShow: false}))
      updateDeclarationsFilters()
    }
  }

  function curFieldChooser() {
    if (curTab === 'cas') {
      const state = window.store.getState().fullInfoReducer.cas;
      return <FieldChooser
        listLeft={state.settings.listLeft.slice(2, state.settings.listLeft.length)}
        listRight={state.settings.listRight}
        onCommit={settingsCommit}
      />
    }
    if (curTab === 'declarations') {
      const state = window.store.getState().fullInfoReducer.declarations;
      return <FieldChooser
        listLeft={state.settings.listLeft.slice(1, state.settings.listLeft.length)}
        listRight={state.settings.listRight}
        onCommit={settingsCommit}
      />
    }
  }

  return (
    <>
      <Stack direction={"row"}>
        <Tabs
          value={curTab}
          onChange={handleChange}
          style={{marginBottom: '1vh', flex: 1}}
        >
          <Tab disableRipple value="cas" label={casString}/>
          <Tab disableRipple value="declarations" label={declarationsString}/>
          <Tab disableRipple value="contracts" label={contractsString}/>
        </Tabs>
        <IconButton style={{height: 'fit-content'}} onClick={() => dispatch(setLeftDrawerCurTab(null))}>
          <CloseOutlinedIcon/>
        </IconButton>
      </Stack>
      <FullInfoButtons/>
      {curTab === 'cas' &&
        <FullInfoCasTab/>
      }
      {curTab === 'declarations' &&
        <FullInfoDelarationsTab/>
      }
      <LeftPanelButtonDump index={'fullInfo'} fullPanel/>
      {tableFieldsSettings &&
        curFieldChooser()
      }
      {filterSettings &&
        <FiltersListsDialog/>
      }
    </>
  )
}
