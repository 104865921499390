import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {declarationDetailsDefaults, setFullInfoDeclarationDetailsData} from "../fullInfoReducer";
import {IconButton, Stack, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import {ErrorAlert} from "../../../../../Common/ErrorAlert";
import {Loading} from "../../../../Map/Common/Loading";

export function DeclarationDetail() {
  const state = useSelector(state => state.fullInfoReducer.declarations.details)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!state.data && !state.loading && !state.loadingError) {
      setTimeout(() => {
        dispatch(setFullInfoDeclarationDetailsData({loading: false, loadingError: true, data: null}))
      }, 1000)
    }
  })

  function handleClose() {
    dispatch(setFullInfoDeclarationDetailsData(structuredClone(declarationDetailsDefaults)))
  }

  return (
    <Stack style={{height: '100%'}}>
      <Stack direction={"row"}>
        <Stack direction={"row"} alignItems={"center"} style={{flex: 1}}>
          <Typography style={{
            fontWeight: 600,
            marginLeft: '0.5vw',
            marginTop: '2px'
          }}>
            {state.selectedDeclaration.name}
          </Typography>
        </Stack>
        <IconButton size={'small'} onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
      </Stack>
      <Box sx={{flex: 1, position: 'relative', mt: 2}}>
        {state.loadingError
          ?
          <ErrorAlert onClick={() => {
            dispatch(setFullInfoDeclarationDetailsData({loading: false, loadingError: false, data: null}))
          }}/>
          :
          state.data
            ?
            state.data.length
              ?
              <DetailsTable data={state.data}/>
              :
              <Typography sx={{width: '100%', pt: 2}} textAlign={'center'}>Нет информации по этой
                декларации</Typography>
            :
            <Loading/>
        }
      </Box>
    </Stack>
  )
}
