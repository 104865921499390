import {getCuttingAreas} from "../../CAs/CuttingsAreas";
import {Stack} from "@mui/material";
import {CAContextMenu} from "../../CAs/CAContextMenu";
import Box from "@mui/material/Box";
import {twentyFour} from "../../../../../Common/Styles";
import RectangleIcon from "@mui/icons-material/Rectangle";
import {ReactComponent as MachineryIcon} from "../../../../Map/Common/svg/machinery.svg";

const machineryIconBordered = {
  border: '1px dashed grey',
  borderRadius: '50%',
}
const machineryIconStyle = {
  boxSizing: 'border-box',
  padding: '2px',
  height: '24px',
  width: '24px',
  fill: 'grey',
}

export function CasStatusIcons({plot, readOnly}) {
  const ca_style = getCuttingAreas().styles[plot.status]
  let machineryStyle = {}

  if (plot.status === 'В разработке') {
    if (!plot.monitoring_data) {
      machineryStyle = machineryIconStyle;
    } else {
      machineryStyle = {...machineryIconStyle, ...machineryIconBordered}
    }
  }

  return (
    <Stack direction={"row"} alignItems={"center"}>
      {!readOnly &&
        <CAContextMenu ca={{id: plot.id}}/>
      }
      <Box style={twentyFour}>
        {plot.status === 'В разработке' &&
          <MachineryIcon style={machineryStyle}/>
        }
      </Box>
      <RectangleIcon
        fontSize={'small'}
        style={{
          stroke: ca_style.color ? ca_style.color : 'green',
          strokeWidth: 2,
          fill: ca_style.fillColor,
          fillOpacity: ca_style.fillOpacity,
          paddingRight: '3px',
          paddingLeft: '3px',
        }}
      />
    </Stack>
  )
}
