import {useDispatch, useSelector} from "react-redux";
import {Loading} from "../../../../Map/Common/Loading";
import {ErrorAlert} from "../../../../../Common/ErrorAlert";
import {cuttingsAreasTemplate, loadCuttingAreas} from "../../CAs/CuttingsAreas";
import {casDataDefaults, setFullInfoCasData} from "../fullInfoReducer";
import {Alert} from "@mui/material";
import {CasTable} from "./CasTable";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {CasDetail} from "./CasDetail";
import {dispatch} from "../../../../../Common/misc_functions";
import {dataAPI} from "../../../../../../api/api";
import {noCasInOrganization} from "../../../../Map/Common/Strings_RU";
import {ReflexibleComponent} from "../ReflexibleComponent";
import {createFilterList} from "../../../../../TableWithBellsAndWhistles/FilterPopup";
import {compareStdFields} from "../Declarations/DeclarationTable";

export function compareFields(a, b, field, direction) {
  const type = field.sortType;
  if (type === 'status') {
    const statuses = [...cuttingsAreasTemplate.defaultStatusFilters, 'В архиве']
    const ind1 = statuses.indexOf(a['status'])
    const ind2 = statuses.indexOf(b['status'])
    return (ind1 - ind2) * direction;
  }

  return compareStdFields(a, b, field, direction)
}

export function sortingCasTable(field, direction) {
  const visibleFields = window.store.getState().fullInfoReducer.cas.visibleFields;
  visibleFields.forEach(f => f !== field && (f.order = null))
  const data = window.store.getState().fullInfoReducer.cas.filteredList;
  data.sort((a, b) => compareFields(a, b, field, direction))
  dispatch(setFullInfoCasData({
      filteredList: [...data],
      visibleFields: [...visibleFields],
      sorting: {column: field.key, direction: direction},
    }
  ))
}

export function updateFilters() {
  const state = window.store.getState().fullInfoReducer.cas;
  const layers = state.data;
  const visibleFields = state.visibleFields;
  const filtered = layers.filter(layer =>
    visibleFields.every(field => {
      if (field.filter) {
        if (field.filter[0].empty && field.filter[0].checked && !layer[field.key]) { //пустое отдельно
          return true;
        }
        return field.filter.some(f => f.name === layer[field.key] && f.checked)
      } else {
        return true;
      }
    }))
  dispatch(setFullInfoCasData({filteredList: filtered}))
  if (state.sorting.column) {
    sortingCasTable(visibleFields.find(field => field.key === state.sorting.column), state.sorting.direction)
  }
}

export function updateAllCasFullInfo() {
  dataAPI.plots.getCasFullData()
    .then(res => {
      dispatch(setFullInfoCasData({data: res.data, filteredList: [...res.data], loading: false}))
      updateFilters()
      const fields = window.store.getState().fullInfoReducer.cas.visibleFields;
      const data = window.store.getState().fullInfoReducer.cas.data;
      fields.forEach(field => {
        if (field.filter) {
          field.filter = createFilterList(data, field.key)
        }
      })
    })
    .catch((err) => {
      console.error(err)
      dispatch(setFullInfoCasData({loading: false, loadingError: true}))
    })
}

export function FullInfoCasTab() {
  const state = useSelector(state => state.fullInfoReducer.cas)
  const panelFlex = window.store.getState().fullInfoReducer.cas.panelFlex;
  const dispatch = useDispatch()

  useEffect(() => {
    if (!state.data && !state.loading && !state.loadingError) {
      dispatch(setFullInfoCasData({data: null, filteredList: null, loading: true, loadingError: false}))
      updateAllCasFullInfo()
    }
  }, [state.data])

  useEffect(() => {
    if (state.settings.listLeft.length === 0) {
      const left = structuredClone(state.visibleFields)
      dispatch(setFullInfoCasData({settings: {...state.settings, listLeft: left}}))
    }
  }, [state.settings.listLeft])

  function topComponent() {
    return (
      <Box style={{position: 'relative', flex: 1}}>
        {state.data.length > 0
          ?
          <CasTable/>
          :
          <Alert severity={'info'}>{noCasInOrganization}</Alert>
        }
      </Box>
    )
  }

  function bottomComponent() {
    return <CasDetail/>
  }

  if (state.loadingError) {
    return (
      <ErrorAlert style={{m: 1}} onClick={() => {
        loadCuttingAreas()
        dispatch(setFullInfoCasData(structuredClone(casDataDefaults)))
      }}/>
    )
  }

  if (!state.data || state.loading) {
    return <Loading/>
  }

  return (
    <>
      <ReflexibleComponent
        topComponent={topComponent}
        bottomComponent={bottomComponent}
        showBottom={state.details.selectedPlot}
        panelFlex={panelFlex}
      />
    </>
  )
}
