import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {casDetailsDefaults, setFullInfoCasDetailsData} from "../fullInfoReducer";
import {CasStatusIcons} from "./CasStatusIcons";
import {Loading} from "../../../../Map/Common/Loading";
import {useEffect} from "react";
import {dataAPI} from "../../../../../../api/api";
import {ErrorAlert} from "../../../../../Common/ErrorAlert";
import Box from "@mui/material/Box";

function CasTableRow({data, last}) {
  const style = {}
  if (last) {
    style.fontWeight = 600;
  }
  return (
    <TableRow>
      {data.map((str, index) => <TableCell key={index} style={style}>{str}</TableCell>)}
    </TableRow>
  )
}

function DetailsTable({data}) {
  return (
    <TableContainer sx={{position: 'absolute', inset: 0}}>
      <Table stickyHeader size={'small'}>
        <TableHead>
          <CasTableRow data={data[0]} key={0}/>
        </TableHead>
        <TableBody>
          {data.slice(1, data.length).map((column, index) =>
            <CasTableRow key={index + 1} data={column} last={index === data.length - 2}/>)
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export function CasDetail() {
  const state = useSelector(state => state.fullInfoReducer.cas.details)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!state.data && !state.loading && !state.loadingError) {
      dataAPI.plots.getCaFullDataDetails(state.selectedPlot.id)
        .then(res => {
          dispatch(setFullInfoCasDetailsData({loading: false, loadingError: false, data: res.data}))
        })
        .catch(() => {
          dispatch(setFullInfoCasDetailsData({loading: false, loadingError: true, data: null}))
        })
    }
  })

  function handleClose() {
    dispatch(setFullInfoCasDetailsData(structuredClone(casDetailsDefaults)))
  }

  return (
    <Stack style={{height: '100%'}}>
      <Stack direction={"row"}>
        <Stack direction={"row"} alignItems={"center"} style={{flex: 1}}>
          <CasStatusIcons plot={state.selectedPlot} readOnly={true}/>
          <Typography style={{
            fontWeight: 600,
            marginLeft: '0.5vw',
            marginTop: '2px'
          }}>
            {state.selectedPlot.name}
          </Typography>
          <Typography style={{
            fontStyle: 'italic',
            marginLeft: '1vw',
            marginTop: '2px'
          }}>
            {state.selectedPlot.status}
          </Typography>
        </Stack>
        <IconButton size={'small'} onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
      </Stack>
      <Box sx={{flex: 1, position: 'relative', mt: 2}}>
        {state.loadingError
          ?
          <ErrorAlert onClick={() => {
            dispatch(setFullInfoCasDetailsData({loading: false, loadingError: false, data: null}))
          }}/>
          :
          state.data
            ?
            state.data.length
              ?
              <DetailsTable data={state.data}/>
              :
              <Typography sx={{width: '100%', pt: 2}} textAlign={'center'}>Нет декларации по этой деляне</Typography>
            :
            <Loading/>
        }
      </Box>
    </Stack>
  )
}
