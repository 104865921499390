import {closestCenter, DndContext, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {useMemo} from "react";
import {CSS} from "@dnd-kit/utilities";
import {IconButton, ListItem, Typography} from "@mui/material";

function ListRow(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id})

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <ListItem
      disablePadding
      id={props.data.key}
      ref={setNodeRef}
      style={{...style, padding: '3px 3px 3px 3px'}}
      {...attributes}
      {...listeners}
    >
      {props.buttonIcon &&
        <IconButton
          size={'small'}
          title={'Убрать'}
          color={'warning'}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            props.onClick(props.data)
          }}
          onMouseDown={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
          onTouchStart={(e) => {
            e.stopPropagation()
          }}
        >
          {props.buttonIcon}
        </IconButton>
      }
      <Typography sx={{userSelect: 'none', flex: 1, cursor: 'move'}}>
        {props.data.name}
      </Typography>
    </ListItem>
  )
}

export function SortableList({items, setItems, onButtonClick = null, buttonIcon = null}) {
  //const [currentID, setCurrentID] = useState(null) //TODO это может понадобится, а может нет
  const itemIds = useMemo(() => items?.map(item => item.key), [items])
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
  )

  function dragEnd(e) {
    const {active, over} = e;
    if (over?.id && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.key === active.id)
      const newIndex = items.findIndex((item) => item.key === over.id)
      setItems(arrayMove(items, oldIndex, newIndex))
    }
    //setCurrentID(null)
  }

  /*function dragStart(event) {
    setCurrentID(event.active.id)
  }*/

  return (
    <DndContext
      //onDragStart={dragStart}
      onDragEnd={dragEnd}
      sensors={sensors}
      collisionDetection={closestCenter}
    >
      <SortableContext strategy={verticalListSortingStrategy} items={itemIds}>
        {items.map(item => {
          return <ListRow
            id={item.key}
            key={item.key}
            data={item}
            buttonIcon={buttonIcon}
            onClick={onButtonClick}
          />
        })}
      </SortableContext>
      <DragOverlay dropAnimation={null}/>
    </DndContext>
  )
}
