import {dispatch} from "../../../../../Common/misc_functions";
import {casDetailsDefaults, setFullInfoDeclarationDetailsData, setFullInfoDeclarationsData} from "../fullInfoReducer";
import {TableCell} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {TableWithBellsAndWhistles} from "../../../../../TableWithBellsAndWhistles/TableWithBellsAndWhistles";
import {downloadFile, isNullish} from "../../../../Map/Common/MiscFunction";
import {convertDateFromUSToRU} from "../../../../Map/Common/DateTimeFunctions";
import {TableCellLink} from "../Common/TableCellLink";
import {useEffect, useRef} from "react";

export function updateDeclarationsFilters() {
  const state = window.store.getState().fullInfoReducer.declarations;
  const declarations = state.data;
  const visibleFields = state.visibleFields;
  const filtered = declarations.filter(declaration =>
    visibleFields.every(field => {
      if (field.filter) {
        if (field.filter[0].empty && field.filter[0].checked && !declaration[field.key]) { //пустое отдельно
          return true;
        }
        return field.filter.some(f => f.name === declaration[field.key] && f.checked)
      } else {
        return true;
      }
    }))
  dispatch(setFullInfoDeclarationsData({filteredList: filtered}))
  if (state.sorting.column) {
    sortingDeclarationsTable(visibleFields.find(field => field.key === state.sorting.column), state.sorting.direction)
  }
}

export function compareStdFields(a, b, field, direction) {

  const type = field.sortType;
  let val1 = a[field.key]
  let val2 = b[field.key]

  const val1N = isNullish(val1)
  const val2N = isNullish(val2)

  if (val1N && !val2N) {
    return direction;
  }

  if (val2N && !val1N) {
    return -direction;
  }

  if (val1N && val2N) {
    return 0;
  }

  if (type === 'string') {
    val1 = val1.toUpperCase()
    val2 = val2.toUpperCase()
  }

  if (val1 > val2) {
    return direction;
  }

  return val1 < val2 ? -direction : 0;
}

export function sortingDeclarationsTable(field, direction) {
  const visibleFields = window.store.getState().fullInfoReducer.declarations.visibleFields;
  visibleFields.forEach(f => f !== field && (f.order = null))
  const data = window.store.getState().fullInfoReducer.declarations.filteredList;
  data.sort((a, b) => compareStdFields(a, b, field, direction))
  dispatch(setFullInfoDeclarationsData({
      filteredList: [...data],
      visibleFields: [...visibleFields],
      sorting: {column: field.key, direction: direction},
    }
  ))
}

function DeclarationCell({item, field, style, linkClick}) {
  let name = item[field.key]
  if (field.sortType === 'link' && field.text) {
    return <TableCellLink item={item} field={field} style={style} linkClick={linkClick} name={field.text}/>
  }

  if (name && field.sortType === 'datestring') {
    name = convertDateFromUSToRU(name)
  }

  return (
    <TableCell
      style={style}
      className={'ellipsis'}
    >
      <span title={name || ''}>{name || ''}</span>
    </TableCell>
  )
}

function getDeclarationFileName(item) {
  return (item.name || 'Декларация') + '.xml';
}

export function DeclarationTable() {
  /**
   * @type {Object[]}
   */
  const data = useSelector(state => state.fullInfoReducer.declarations.filteredList)
  const visibleFields = useSelector(state => state.fullInfoReducer.declarations.visibleFields)
  const sorting = useSelector(state => state.fullInfoReducer.declarations.sorting)
  const fullPanelWidth = useSelector(state => state.leftDrawerReducer.panelFullWidth)
  const selected = useSelector(state => state.fullInfoReducer.declarations.selected)
  const goToItemID = useSelector(state => state.fullInfoReducer.declarations.goToItemID)
  const dispatch = useDispatch()
  /**
   * @type {React.MutableRefObject<{scrollToIndex}>}
   */
  const virtuosoRef = useRef()

  useEffect(() => {
    if (goToItemID) {
      //TODO Ищет по видимиы полям. Это правильно? Может, если отфильтровано, добавлять в её в этот список?
      const index = data.findIndex(item => item.id === goToItemID)
      if (index !== -1) {
        const item = data[index]
        dispatch(setFullInfoDeclarationsData({selected: item, goToItemID: null}))
        setTimeout(() => {
          virtuosoRef.current.scrollToIndex({index: index})
        }, 200)
      }
    }
  }, [goToItemID])

  function tableRowClick(item) {
    const selectedDeclaration = window.store.getState().fullInfoReducer.declarations.details.selectedDeclaration;
    const selectionType = document.getSelection()?.type;
    if (!selectionType || selectionType !== 'Range') {
      if (selectedDeclaration !== item) {
        dispatch(setFullInfoDeclarationDetailsData({...casDetailsDefaults, selectedDeclaration: item, data: null}))
      } else {
        dispatch(setFullInfoDeclarationDetailsData(structuredClone(casDetailsDefaults)))
      }
    }
    dispatch(setFullInfoDeclarationsData({selected: item}))
  }

  function linkClick(field, item) {
    if (field.key === 'declaration_xml_file') {
      const url = item[field.linkField]
      downloadFile(url, getDeclarationFileName(item))
    }
  }

  return (
    <Box style={{position: 'absolute', inset: 0}}>
      <TableWithBellsAndWhistles
        _ref={virtuosoRef}
        data={data}
        visibleFields={visibleFields}
        getCell={(item, field, style, linkClick) =>
          <DeclarationCell key={field.key} item={item} field={field} style={style} linkClick={linkClick}/>
        }
        rowClick={tableRowClick}
        sorting={sorting}
        fieldSorting={sortingDeclarationsTable}
        updateFilters={() => updateDeclarationsFilters()}
        selected={selected}
        linkClick={linkClick}
        stickyColumnsCount={fullPanelWidth ? 1 : 0}
        //stickyColumnsCount={0}
      />
    </Box>
  )
}
