import {useDispatch, useSelector} from "react-redux";
import {ReflexibleComponent} from "../ReflexibleComponent";
import {Alert} from "@mui/material";
import {noDeclarationInOrganization} from "../../../../Map/Common/Strings_RU";
import Box from "@mui/material/Box";
import {ErrorAlert} from "../../../../../Common/ErrorAlert";
import {declarationDataDefaults, setFullInfoDeclarationsData,} from "../fullInfoReducer";
import {Loading} from "../../../../Map/Common/Loading";
import {useEffect} from "react";
import {dataAPI} from "../../../../../../api/api";
import {dispatch} from "../../../../../Common/misc_functions";
import {createFilterList} from "../../../../../TableWithBellsAndWhistles/FilterPopup";
import {DeclarationTable, updateDeclarationsFilters} from "./DeclarationTable";
import {DeclarationDetail} from "./DeclarationDetail";

export function updateAllDeclarationFullInfo() {
  dataAPI.declarations.getAll()
    .then(res => {
      //console.log(res.data)
      dispatch(setFullInfoDeclarationsData({data: res.data, filteredList: [...res.data], loading: false}))
      updateDeclarationsFilters()
      const fields = window.store.getState().fullInfoReducer.declarations.visibleFields;
      const data = window.store.getState().fullInfoReducer.declarations.data;
      fields.forEach(field => {
        if (field.filter) {
          field.filter = createFilterList(data, field.key)
        }
      })
    })
    .catch(() => {
      dispatch(setFullInfoDeclarationsData({loading: false, loadingError: true}))
    })
}

export function FullInfoDelarationsTab() {
  const state = useSelector(state => state.fullInfoReducer.declarations)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!state.data && !state.loading && !state.loadingError) {
      dispatch(setFullInfoDeclarationsData({data: null, filteredList: null, loading: true, loadingError: false}))
      updateAllDeclarationFullInfo()
    }
  }, [state.data])

  useEffect(() => {
    if (state.settings.listLeft.length === 0) {
      const left = structuredClone(state.visibleFields)
      dispatch(setFullInfoDeclarationsData({settings: {...state.settings, listLeft: left}}))
    }
  }, [state.settings.listLeft])


  function topComponent() {
    if (state.loading || state.loadingError) {
      return null;
    }
    return (
      <Box style={{position: 'relative', flex: 1}}>
        {state.data.length > 0
          ?
          <DeclarationTable/>
          :
          <Alert severity={'info'}>{noDeclarationInOrganization}</Alert>
        }
      </Box>
    )
  }

  function bottomComponent() {
    return (
      <DeclarationDetail/>
    )
  }

  if (state.loadingError) {
    return (
      <ErrorAlert style={{m: 1}} onClick={() => {
        dispatch(setFullInfoDeclarationsData(structuredClone(declarationDataDefaults)))
      }}/>
    )
  }

  if (!state.data || state.loading) {
    return <Loading/>
  }

  return (
    <>
      <ReflexibleComponent
        topComponent={topComponent}
        bottomComponent={bottomComponent}
        showBottom={state.details.selectedDeclaration}
        panelFlex={state.panelFlex}
      />
    </>
  )
}
