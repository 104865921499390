import {getDrawLayerGroup} from "../../../Map/GlobalObjects";
import 'leaflet-geometryutil/src/leaflet.geometryutil'
import {getIL1Popup, prepareToOpenPopup} from "../../../Map/MapPopup/popupFunctions";
import {getSentinelDate} from "../../../Map/Common/DateTimeFunctions";
import {getBaseLayers} from "../../../Overlay/BaseLayers/BaseLayersCommon";
import {getReportUseForestDataThunk} from "../../../../../redux/reducers/forestUseReportReducer";
import {geoJSONdefaultPrecision} from "../../../Map/Common/Defaults";
import {closeMapPopup, showMapPopup} from "../../../Map/MapPopup/mapPopup";

let report_poly = null;

export function resetIL1Report() {
  if (report_poly) {
    report_poly.off('click')
    report_poly.off('pm:dragstart')
    report_poly.off()
    getDrawLayerGroup().removeLayer(report_poly)
  }
}

export function createIL1Area(layer, dispatch) {
  const drawLayersGroup = getDrawLayerGroup()
  const h_meters = 5250;
  const w_meters = 4750;
  const center = layer.getCenter()
  const no_north = L['GeometryUtil'].destination(center, 0, h_meters / 2)
  const north_west = L['GeometryUtil'].destination(no_north, 270, w_meters / 2)
  const south_west = L['GeometryUtil'].destination(north_west, 180, h_meters)
  const south_east = L['GeometryUtil'].destination(south_west, 90, w_meters)
  const north_east = L['GeometryUtil'].destination(south_east, 0, h_meters)

  if (report_poly)
    drawLayersGroup.removeLayer(report_poly)

  const poly = L.polygon([north_west, south_west, south_east, north_east, north_west], {
    color: 'red',
    weight: 2,
    pane: 'layerPane',
    isReport: true,
  })
  drawLayersGroup.addLayer(poly)
  poly.pm.enableLayerDrag()

  poly.on('pm:dragstart', () => {poly.elz_dragging = true})
  poly.on('click', (e) => {
    if (!prepareToOpenPopup(e))
      return;
    if (poly.elz_dragging)
      delete poly.elz_dragging;
    else {
      const interactive = {
        'map_reports_il1_commit': {
          type: 'click',
          f: () => {
            closeMapPopup()
            const dateNow = getSentinelDate(getBaseLayers()['sentinel'].time1)
            const dateOld = getSentinelDate(getBaseLayers()['sentinel'].time2)
            const json = {
              feature: {
                type: 'Polygon',
                coordinates: poly.toGeoJSON(geoJSONdefaultPrecision).geometry.coordinates,
              },
              date_to: dateOld,
              date_from: dateNow,
              d_plot_id: layer.elz_properties.id,
            }
            dispatch(getReportUseForestDataThunk(json))
            resetIL1Report()
          }
        },
        'map_reports_il1_cancel': {
          type: 'click',
          f: () => {
            closeMapPopup()
            resetIL1Report()
          }
        },
      }
      const html = getIL1Popup()
      showMapPopup(e.latlng, html, interactive)
    }
  })
  report_poly = poly;
}
