import {Box} from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";
import {LeftPanelButtonDump} from "./LeftPanelButtonDump";

export function LeftDrawerPanel(props) { // левая панель (не кнопки)
  return (
    <Box
      id={'left_drawer_panel'}
      style={{
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid ' + window.elz_theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0',
        boxSizing: 'border-box',
      }}
    >
      <Stack style={{margin: '0 1vh 1vh 1vh', flex: 1, boxSizing: 'border-box'}}>
        {props.children}
        <LeftPanelButtonDump index={props.index}/>
      </Stack>
    </Box>
  )
}
