import {ReflexContainer, ReflexElement, ReflexSplitter} from "react-reflex";
import {Stack} from "@mui/material";

export function ReflexibleComponent({showBottom, topComponent, bottomComponent, panelFlex}) {

  return (
    <>
      <ReflexContainer orientation={'horizontal'} style={{height: '100%', overflow: 'hidden'}}>
        <ReflexElement
          minSize={200}
          style={{overflow: 'hiden'}}
        >
          <Stack direction={'column'} style={{height: '100%'}}>
            {topComponent()}
          </Stack>
        </ReflexElement>
        {showBottom &&
          <ReflexSplitter
            className={'reflex-splitter-horizontal'}
          />
        }
        {showBottom &&
          <ReflexElement
            minSize={100}
            style={{overflow: 'hidden', height: '100%'}}
            flex={panelFlex}>
            {bottomComponent()}
          </ReflexElement>
        }
      </ReflexContainer>
    </>
  )
}
